//react
import React, { useState, useEffect } from "react";

//components
import AddLocationStepTwo from "./../AddLocationStepTwo";
import { LocationText } from "./../common/LocationText";
import { HeaderDiv } from "../common/HeaderDiv";
import { toastSuccess, toastError } from "../../../utils/toasts";
import Overlay from "../../common/overlay/Overlay";

//style
import { useTheme } from "../../../styling/styled-components";

//api
import * as mutations from "../../../graphql/mutations";
import useGraphClient from "../../../hooks/useGraphClient";
import * as queries from "../../../graphql/queries";
import { useQuery } from "../../../graphql/hooks/query";

//constants
import { emptyMetadata } from "../../../constants/locations";

//utils
import { handleUsernameChange } from "../../../utils/locations/handleUsernameChange";
import { returnStepDescription } from "../../../utils/locations/returnStepDescription";

export default function AddScanLocation({
    handleCloseAddScanOverlay,
    parentLocationId,
    onTriggerBackToParentOverlay,
    overlayOpen,
    triggerRefresh,
}) {
    //variables for step 2
    const [stepNumber, setStepNumber] = useState(2);
    const [headerText, setHeaderText] = useState({
        stepDescription: "",
        stepInstruction: "",
        stepSubDescription: "",
    });
    const [loading, setLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    //scanning-related states
    const [scanningLocationName, setScanningLocationName] = useState("");
    const [showAddButton, setShowAddButton] = useState(false);

    //variables for apis
    const [scanningLocations, setScanningLocations] = useState([]);
    const [newMetaData, setNewMetaData] = useState<array>([emptyMetadata]);

    //style
    const { colors } = useTheme();

    //hooks
    const graphClient = useGraphClient();

    useEffect(() => {
        handleUpdateHeaderText();
    }, [stepNumber, showAddButton]);

    const [
        getParentLocation,
        getParentLocationData,
        getParentLocationLoading,
        getParentLocationErrors,
    ] = useQuery(queries.getParentLocation);

    useEffect(() => {
        getParentLocation({ locationId: parentLocationId });
        setShowAddButton(false);
    }, [parentLocationId]);

    useEffect(() => {
        setShowAddButton(false);
        if (getParentLocationData) {
            setShowAddButton(false);
            setDataLoaded(true);
            setScanningLocationName("");
        }
    }, [getParentLocationData]);

    const handleUpdateHeaderText = () => {
        const updatedText = returnStepDescription(stepNumber, showAddButton);
        setHeaderText({ ...headerText, ...updatedText });
    };

    const onClickAddScanLocation = () => {
        setShowAddButton(false);
        setStepNumber(2);
    };

    const triggerBackButton = () => {
        if (stepNumber == 2) {
            onTriggerBackToParentOverlay();
        }
    };

    const renderAddLocationStepNumber = () => {
        if (stepNumber == 2) {
            return (
                <AddLocationStepTwo
                    showAddButton={showAddButton}
                    onClickAddScanLocation={onClickAddScanLocation}
                    scanningLocationName={scanningLocationName}
                    scanningLocations={scanningLocations}
                    addAndSaveScan={addAndSaveScan}
                    locationPrefix={getParentLocationData.name}
                    handleUsernameChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                    ) =>
                        handleUsernameChange(
                            event,
                            setScanningLocationName,
                            getParentLocationData.name
                        )
                    }
                    parentLocationId={parentLocationId}
                    setNewMetaData={setNewMetaData}
                    newMetaData={newMetaData}
                    loading={loading}
                ></AddLocationStepTwo>
            );
        }
    };

    const reformattedNewMetadata = newMetaData.map((md) => {
        return Object.keys(md)
            .filter((key) => key.includes("key") || key.includes("value"))
            .reduce((cur, key) => {
                return Object.assign(cur, { [key]: md[key] });
            }, {});
    });

    const addScanningLocation = async () => {
        setLoading(true);
        try {
            const result = await graphClient.graphql({
                query: mutations.createLocation,
                variables: {
                    input: {
                        name: scanningLocationName,
                        parentId: parentLocationId,
                        metadata: reformattedNewMetadata,
                    },
                },
            });
            const createLocationData = result.data.createLocation;
            setScanningLocations((prev) => [...prev, createLocationData]);
            setScanningLocationName("");
            setLoading(false);
            setNewMetaData([emptyMetadata]);
            if (result) {
                toastSuccess("The scanning location was successfully added");
            }
        } catch (err) {
            console.error("Error adding scanning location", err);
            toastError("The scanning location could not be added");
        }
    };

    const addAndSaveScan = () => {
        addScanningLocation();
        setShowAddButton(false);
        onTriggerBackToParentOverlay();
        triggerRefresh();
    };

    return (
        <>
            {dataLoaded && (
                <Overlay
                    onClickCloseOverlay={handleCloseAddScanOverlay}
                    showLeft={true}
                    onClickBack={triggerBackButton}
                    onClickedOutside={handleCloseAddScanOverlay}
                    overlayOpen={overlayOpen}
                    scroll={"scroll"}
                >
                    <>
                        <HeaderDiv>
                            <LocationText
                                type="body"
                                size="medium"
                                color={colors.EXHAUST}
                            >
                                {getParentLocationData.name}
                            </LocationText>
                            <LocationText
                                type="header"
                                size="large"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {headerText.stepInstruction}
                            </LocationText>
                            <LocationText
                                type="body"
                                size="medium"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {headerText.stepDescription}
                            </LocationText>
                            <LocationText
                                type="body"
                                size="medium"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {headerText.stepSubDescription}
                            </LocationText>
                        </HeaderDiv>
                        {renderAddLocationStepNumber()}
                    </>
                </Overlay>
            )}
        </>
    );
}
