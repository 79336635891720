//react
import { useState, useEffect } from "react";

//components
import Text from "../common/Text";
import SideLine from "../common/SideLine";
import { LocationInputForm } from "./common/LocationInputField";
import { NextButton } from "./common/NextButton";
import LocationTag from "./common/LocationTag";

//utils
import { checkMetaDataComplete } from "../../utils/locations/checkMetaData";

//style
import styled, { useTheme } from "../../styling/styled-components";

//apis
import { useQuery } from "../../graphql/hooks/query";
import * as queries from "../../graphql/queries";

//constants
import { LOCATION_STEP_2_STRINGS } from "../../constants/locations";

const ButtonText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const TagsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.xlarge}px
        ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.large}px;
`;

const LineAddTag = styled.div``;

const AddTagText = styled(Text)`
    cursor: pointer;
    width: 200px;
`;

const TagDiv = styled.div`
    position: relative;
    left: ${({ theme }) => -theme.spacing.xsmall}%;
`;

const ButtonWrap = styled.div`
    width: ${({ theme }) => theme.spacing.small}%;
    padding-left: ${({ theme }) => theme.spacing.huge}px;
    margin-left: ${({ theme }) => theme.spacing.huge}px;
`;

const ButtonDiv = styled.div`
    position: relative;
    margin-top: 50%;
    padding: ${({ theme }) => theme.spacing.medium}px;
    padding-bottom: ${({ theme }) => theme.spacing.xlarge}px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: ${({ theme }) => theme.shadowStyle.overlayDropShadow};
`;

const ButtonTagDiv = styled.div`
    position: absolute;
    bottom: 0;
    padding: ${({ theme }) => theme.spacing.medium}px;
    padding-bottom: ${({ theme }) => theme.spacing.yuge}px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: ${({ theme }) => theme.shadowStyle.overlayDropShadow};
`;

type Props = {
    addAndSaveScan: () => void;
    scanningLocationName: string;
    locationPrefix: string;
    setNewMetaData: () => void;
    newMetaData: string;
    parentLocationId: string;
    handleUsernameChange: () => void;
};

export default function AddLocationStepTwoPartB({
    addAndSaveScan,
    scanningLocationName,
    locationPrefix,
    setNewMetaData,
    newMetaData,
    parentLocationId,
    handleUsernameChange,
}: Props) {
    const { colors, spacing } = useTheme();
    const [showTags, setShowTags] = useState(false);
    const [isLabelHovered, setIsLabelHovered] = useState(true);
    const [enableButton, setEnableButton] = useState(false);
    const [showNameErrors, setShowNameErrors] = useState<boolean>(false);
    const [showScanningNameErrors, setShowScanningNameErrors] =
        useState<boolean>(false);
    const [shouldShowMetadataErrors, setShouldShowMetadataErrors] =
        useState<boolean>(false);

    const toggleLabelHover = () => {
        setIsLabelHovered(false);
    };

    const toggleLabelHoverOff = () => {
        setIsLabelHovered(true);
    };

    const [
        getParentLocation,
        getParentLocationData,
        getParentLocationLoading,
        getParentLocationErrors,
    ] = useQuery(queries.getParentLocation);

    const emptyMetadata = {
        key: "",
        value: "",
        isNew: true,
    };

    useEffect(() => {
        setNewMetaData([emptyMetadata]);
        if (parentLocationId) {
            getParentLocation({ locationId: parentLocationId });
        }
    }, [parentLocationId, scanningLocationName]);

    useEffect(() => {
        checkMetaDataComplete(newMetaData, setShouldShowMetadataErrors);
    }, [newMetaData]);

    useEffect(() => {
        setIsLabelHovered(true);
    }, [showTags]);

    useEffect(() => {
        setShowScanningNameErrors(false);
        setShowNameErrors(false);
    }, [scanningLocationName, shouldShowMetadataErrors]);

    useEffect(() => {
        if (getParentLocationData) {
            setEnableButton(false);
            checkUniqueScanningName();
        }
    }, [scanningLocationName.length, shouldShowMetadataErrors]);

    const checkUniqueScanningName = () => {
        if (
            scanningLocationName.trimStart().trimEnd() ==
            locationPrefix.trimEnd()
        ) {
            setShowNameErrors(true);
            setEnableButton(false);
            return;
        }
        if (getParentLocationData.locations.length > 0) {
            getParentLocationData.locations.map((item, index) => {
                if (scanningLocationName.trimStart() == item.name) {
                    setShowScanningNameErrors(true);
                    setEnableButton(false);
                    return;
                }
            });
        }
        if (
            scanningLocationName.trimStart().trimEnd().length > 0 &&
            !shouldShowMetadataErrors
        ) {
            setEnableButton(true);
            return;
        }
    };

    const saveScanLocation = () => {
        addAndSaveScan();
        setEnableButton(false);
    };

    const TagComponent = () => {
        return (
            <>
                <div
                    style={{
                        position: "relative",
                        height: 444,
                        overflowY: "scroll",
                        marginBottom: spacing.yuge,
                    }}
                >
                    <LocationTag
                        newMetaData={newMetaData}
                        setNewMetaData={setNewMetaData}
                        shouldShowMetadataErrors={shouldShowMetadataErrors}
                        setShowTags={setShowTags}
                    ></LocationTag>
                </div>
                <ButtonTagDiv>
                    <NextButton
                        type="primary"
                        onClick={saveScanLocation}
                        status={
                            enableButton && !shouldShowMetadataErrors
                                ? "default"
                                : "disabled"
                        }
                        label={
                            <ButtonText
                                type="label"
                                size="large"
                                color={colors.BLANK_SLATE}
                            >
                                {LOCATION_STEP_2_STRINGS.SAVE_SCANNING}
                            </ButtonText>
                        }
                    ></NextButton>
                </ButtonTagDiv>
            </>
        );
    };

    return (
        <>
            <LocationInputForm
                infoText={LOCATION_STEP_2_STRINGS.SAVE_NAME}
                maxLength={150}
                width="medium"
                hasError={
                    showNameErrors || showScanningNameErrors ? true : false
                }
                infoStyle={{
                    color: colors.ASH,
                }}
                error={
                    showNameErrors
                        ? LOCATION_STEP_2_STRINGS.ERROR_UNIQUE_SCAN_VS_PARENT_NAME
                        : LOCATION_STEP_2_STRINGS.ERROR_NAME_EXISTS
                }
                onChange={(e) => handleUsernameChange(e)}
                value={`${locationPrefix} - ${scanningLocationName}`}
            ></LocationInputForm>
            {!showTags ? (
                <>
                    <TagsContainer>
                        <LineAddTag>
                            <SideLine></SideLine>
                        </LineAddTag>
                        <TagDiv
                            style={{
                                position: "relative",
                            }}
                        >
                            <ButtonWrap
                                onMouseEnter={toggleLabelHover}
                                onMouseLeave={toggleLabelHoverOff}
                            >
                                <AddTagText
                                    type="label"
                                    size="medium"
                                    onClick={() => setShowTags(true)}
                                    color={
                                        isLabelHovered
                                            ? colors.WAVE_STORM
                                            : colors.PLASTIC_BOTTLE
                                    }
                                >
                                    {LOCATION_STEP_2_STRINGS.ADD_TAG}
                                </AddTagText>
                            </ButtonWrap>
                        </TagDiv>
                        <LineAddTag>
                            <SideLine></SideLine>
                        </LineAddTag>
                    </TagsContainer>
                    <ButtonTagDiv>
                        <NextButton
                            type="primary"
                            onClick={saveScanLocation}
                            status={
                                enableButton && !shouldShowMetadataErrors
                                    ? "default"
                                    : "disabled"
                            }
                            label={
                                <ButtonText
                                    type="label"
                                    size="large"
                                    color={colors.BLANK_SLATE}
                                >
                                    {LOCATION_STEP_2_STRINGS.SAVE_SCANNING}
                                </ButtonText>
                            }
                        ></NextButton>
                    </ButtonTagDiv>
                </>
            ) : (
                <>
                    <div
                        style={{
                            position: "relative",
                            height: 350,
                            overflowY: "scroll",
                            marginBottom: spacing.yuge,
                        }}
                    >
                        <LocationTag
                            newMetaData={newMetaData}
                            setNewMetaData={setNewMetaData}
                            shouldShowMetadataErrors={shouldShowMetadataErrors}
                            setShowTags={setShowTags}
                        ></LocationTag>
                    </div>
                    <ButtonTagDiv>
                        <NextButton
                            type="primary"
                            onClick={saveScanLocation}
                            status={
                                enableButton && !shouldShowMetadataErrors
                                    ? "default"
                                    : "disabled"
                            }
                            label={
                                <ButtonText
                                    type="label"
                                    size="large"
                                    color={colors.BLANK_SLATE}
                                >
                                    {LOCATION_STEP_2_STRINGS.SAVE_SCANNING}
                                </ButtonText>
                            }
                        ></NextButton>
                    </ButtonTagDiv>
                </>
            )}
        </>
    );
}
