//react
import React, { useEffect, useState } from "react";

//components
import LocationCard from "./LocationCard";
import Text from "../common/Text";
import ClipLoader from "react-spinners/ClipLoader";
import LocationsOverlay from "./LocationsOverlay";
import ParentLocationOverlay from "./parentLocation/ParentLocationOverlay";
import ScanningLocationOverlay from "./scanningLocation/ScanningLocationOverlay";
import EditParentLocation from "./parentLocation/EditParentLocation";
import AddScanLocation from "./scanningLocation/AddScanLocation";
import UnsavedChangesBlocker from "../common/UnsavedChangesBlocker";
import { toastSuccess, toastError } from "../../utils/toasts";
import Loadable from "../common/Loadable";
import LocationScreenHeader from "./LocationScreenHeader";

//style
import styled, { useTheme } from "styled-components/macro";

//api
import * as queries from "../../graphql/queries";
import { useQuery } from "../../graphql/hooks/query";
import * as mutations from "../../graphql/mutations";
import useGraphClient from "../../hooks/useGraphClient";

//utils
import { toggleOverlay } from "../../utils/overlay/toggleOverlay";

const EmptyLocationList = styled.div`
    height: 76px;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    text-align: center;
    margin-top: ${({ theme }) => theme.spacing.xxlarge}px;
    text-align: center;
    margin-top: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const EmptyLocationText = styled(Text)`
    width: 487.3px;
    display: inline-block;
    margin-top: ${({ theme }) => theme.spacing.large}px;
`;

const AddLocationButton = styled(Text)`
    background: ${({ theme }) => theme.colors.BLANK_SLATE};
    border-radius: 4px;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.small}px;
    &:hover {
        background-color: ${({ theme }) => theme.colors.SEAGLASS};
        cursor: pointer;
    }
`;

const AddlocationDiv = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    top: ${({ theme }) => -theme.spacing.xsmall}px;
`;

const LocationCountText = styled(Text)`
    position: relative;
    top: ${({ theme }) => theme.spacing.xlarge}px;
`;

const LocationListDiv = styled.div``;

const LOCATION_STRING = {
    NO_DATA: "There are no locations set up yet.",
    PARENT_LOCATION: "Parent locations",
    PLUS: "+",
    ADD_LOCATION: "Add location",
    UNSAVED_CHANGES_HEADER: "You have unsaved changes",
    UNSAVED_CHANGES_BACK:
        "If you go back without saving, your changes will be lost. Are you sure you want to continue?",
    UNSAVED_CHANGES_CLOSE:
        "If you close without saving, your changes will be lost. Are you sure you want to continue?",
    ARCHIVE_PARENT:
        "Once archived, all data associated with this parent location will be removed from the dashboard. ",
    ARCHIVE_SCAN:
        "Once archived, all data associated with this scanning location, including any rentals and asset events, will be removed from the dashboard. After archiving, ensure that all ScanApp accounts associated with this scanning location have been re-assigned. ",
    BUTTON_CANCEL: "Cancel",
    BUTTON_CONTINUE: "Continue",
    BUTTON_ARCHIVE: "Archive now",
};

export default function Locations(props) {
    const { colors, spacing } = useTheme();

    const graphClient = useGraphClient();

    //overlays
    const [saveButtonClicked, setSaveButtonClicked] = useState(false);
    const [showOverlayState, setShowOverlayState] = useState({
        addLocationOverlay: false,
        parentOverlay: false,
        scanningOverlay: false,
        editParentOverlay: false,
        addScanningOverlay: false,
    });

    //locationId
    const [parentLocationId, setParentLocationId] = useState("");
    const [scanningLocationId, setScanningLocationId] = useState("");

    //unsaved changes
    const [unsavedChangesBack, setUnsavedChangesBack] = useState(false);
    const [unsavedChangesClose, setUnsavedChangesClose] = useState(false);
    const [backButtonClicked, setBackButtonClicked] = useState(false);

    //archive locations
    const [parentLocationName, setParentLocationName] = useState("");
    const [scanningLocationName, setScanningLocationName] = useState("");
    const [archiveModal, setArchiveModal] = useState(false);
    const [archiveScanModal, setArchiveScanModal] = useState(false);

    const handleToggleOverlayState = (overlay) => {
        setShowOverlayState((prevState) => {
            return toggleOverlay(overlay, prevState);
        });
    };

    useEffect(() => {
        setSaveButtonClicked(false);
        listLocations();
    }, [saveButtonClicked]);

    //add location overlay functions
    const onClickCloseAddLocationAndRefresh = () => {
        handleToggleOverlayState("addLocationOverlay");
        triggerRefresh();
    };

    //parent overlay functions
    const onClickOpenParentOverlayAndSetValues = (id, name) => {
        handleToggleOverlayState("parentOverlay");
        handleSetParentIdAndName(id, name);
    };

    const handleSetParentIdAndName = (id, name) => {
        setParentLocationId(id);
        setParentLocationName(name);
    };

    const closeEditParentAndTriggerRefresh = (id) => {
        handleToggleOverlayState("editParentOverlay");
        onClickOpenParentOverlayAndSetValues(id);
        triggerRefresh();
    };

    //scanning overlay functions
    const onClickOpenScanningOverlay = (id) => {
        handleToggleOverlayState("scanningOverlay");
        setScanningLocationId(id);
    };

    const onTriggerBackToParentOverlay = (id, overlay) => {
        handleToggleOverlayState(overlay);
        onClickOpenParentOverlayAndSetValues(id);
    };

    //blocker functions
    const closeBlockerAndExitOverlay = () => {
        setUnsavedChangesClose(false);
        onClickCloseAddLocationAndRefresh();
    };

    const triggerBackBlocker = () => {
        setUnsavedChangesBack(false);
        setBackButtonClicked(true);
    };

    const resetBackButton = () => {
        setBackButtonClicked(false);
    };

    const triggerRefresh = () => {
        setSaveButtonClicked(true);
    };

    const toggleUnsavedChangesBack = (showBlocker) => {
        if (showBlocker) {
            setUnsavedChangesBack(!unsavedChangesBack);
        } else {
            triggerBackBlocker();
        }
    };

    const toggleUnsavedChangesClose = (showBlocker) => {
        if (showBlocker) {
            setUnsavedChangesClose(!unsavedChangesClose);
        } else {
            closeBlockerAndExitOverlay();
        }
    };

    useEffect(() => {
        if (backButtonClicked) {
            if (showOverlayState.scanningOverlay) {
                onTriggerBackToParentOverlay(
                    parentLocationId,
                    "scanningOverlay"
                );
            } else {
                onTriggerBackToParentOverlay(
                    parentLocationId,
                    "editParentOverlay"
                );
            }
            resetBackButton();
        }
    }, [backButtonClicked]);

    const [
        listLocations,
        listLocationsData,
        listLocationsLoading,
        listLocationsErrors,
    ] = useQuery(queries.listParentLocations);

    const renderLocationsList = () => {
        const alphabetizeLocations = [...listLocationsData?.results].sort(
            (a, b) => a.name.localeCompare(b.name)
        );

        return alphabetizeLocations.map((location) => (
            <LocationCard
                onClick={() =>
                    onClickOpenParentOverlayAndSetValues(
                        location.locationId,
                        location.name
                    )
                }
                address={location.address}
                googlePlacesId={location.address?.googlePlacesId}
                nickname={location.name}
                key={location.locationId}
                locations={location.locations}
            />
        ));
    };

    const renderEmptyLocationsList = () => {
        return (
            <EmptyLocationList>
                <EmptyLocationText
                    type="label"
                    size="small"
                    color={colors.DEEP_BLUE_SEA}
                >
                    {LOCATION_STRING.NO_DATA}
                </EmptyLocationText>
            </EmptyLocationList>
        );
    };

    //archive locations
    const toggleArchiveModal = () => {
        setArchiveModal(!archiveModal);
    };

    const toggleArchiveScanModal = (name) => {
        setArchiveScanModal(!archiveScanModal);
        setScanningLocationName(name);
    };

    const onSuccessfulArchiveParent = () => {
        toggleArchiveModal();
        handleToggleOverlayState("parentOverlay");
        triggerRefresh();
        toastSuccess("The parent location was successfully archived");
    };

    const onSuccessfulArchiveScanLocation = () => {
        toggleArchiveScanModal();
        handleToggleOverlayState("parentOverlay");
        handleToggleOverlayState("scanningOverlay");
        triggerRefresh();
        toastSuccess("The scanning location was successfully archived");
    };

    const archiveParentLocation = async () => {
        try {
            const result = await graphClient.graphql({
                query: mutations.archiveParentLocation,
                variables: {
                    input: { locationId: parentLocationId },
                },
            });
            if (result) {
                onSuccessfulArchiveParent();
            }
        } catch (error) {
            toastError("The parent location could not be archived.");
            console.error("Error archiving parent location", error);
        }
    };

    const archiveLocation = async () => {
        try {
            const result = await graphClient.graphql({
                query: mutations.archiveLocation,
                variables: {
                    input: { locationId: scanningLocationId },
                },
            });
            if (result) {
                onSuccessfulArchiveScanLocation();
            }
        } catch (error) {
            toastError("The scanning location could not be archived.");
            console.error("Error archiving scanning location", error);
        }
    };

    return (
        <>
            <LocationScreenHeader />
            <Loadable dataLoaded={!listLocationsLoading}>
                <>
                    <LocationCountText
                        type="label"
                        size="small"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        {LOCATION_STRING.PARENT_LOCATION +
                            "   " +
                            `(${listLocationsData?.results.length})`}
                    </LocationCountText>
                    <AddlocationDiv>
                        <AddLocationButton
                            type="label"
                            size="small"
                            color={colors.WAVE_STORM}
                            onClick={() =>
                                handleToggleOverlayState("addLocationOverlay")
                            }
                        >
                            {LOCATION_STRING.PLUS +
                                " " +
                                LOCATION_STRING.ADD_LOCATION}
                        </AddLocationButton>
                    </AddlocationDiv>
                </>
            </Loadable>
            <LocationListDiv>
                {listLocationsLoading ? (
                    <ClipLoader loading />
                ) : !listLocationsData || listLocationsData.length == 0 ? (
                    renderEmptyLocationsList()
                ) : (
                    renderLocationsList()
                )}
            </LocationListDiv>
            <UnsavedChangesBlocker
                header={LOCATION_STRING.UNSAVED_CHANGES_HEADER}
                subheader={LOCATION_STRING.UNSAVED_CHANGES_CLOSE}
                buttonCancelText={LOCATION_STRING.BUTTON_CANCEL}
                buttonActionText={LOCATION_STRING.BUTTON_CONTINUE}
                onClickBack={toggleUnsavedChangesClose}
                onClickAction={closeBlockerAndExitOverlay}
                isOpen={unsavedChangesClose}
            ></UnsavedChangesBlocker>
            <UnsavedChangesBlocker
                header={LOCATION_STRING.UNSAVED_CHANGES_HEADER}
                subheader={LOCATION_STRING.UNSAVED_CHANGES_BACK}
                buttonCancelText={LOCATION_STRING.BUTTON_CANCEL}
                buttonActionText={LOCATION_STRING.BUTTON_CONTINUE}
                onClickBack={toggleUnsavedChangesBack}
                onClickAction={triggerBackBlocker}
                isOpen={unsavedChangesBack}
            ></UnsavedChangesBlocker>
            <UnsavedChangesBlocker
                header={`Are you sure you want to archive ${parentLocationName}?`}
                subheader={LOCATION_STRING.ARCHIVE_PARENT}
                buttonCancelText={LOCATION_STRING.BUTTON_CANCEL}
                buttonActionText={LOCATION_STRING.BUTTON_ARCHIVE}
                onClickBack={toggleArchiveModal}
                onClickAction={archiveParentLocation}
                isOpen={archiveModal}
            ></UnsavedChangesBlocker>
            <UnsavedChangesBlocker
                header={`Are you sure you want to archive ${scanningLocationName}?`}
                subheader={LOCATION_STRING.ARCHIVE_SCAN}
                buttonCancelText={LOCATION_STRING.BUTTON_CANCEL}
                buttonActionText={LOCATION_STRING.BUTTON_ARCHIVE}
                onClickBack={toggleArchiveScanModal}
                onClickAction={archiveLocation}
                isOpen={archiveScanModal}
                height={`${spacing.xmlarge}%`}
            ></UnsavedChangesBlocker>
            <LocationsOverlay
                listLocationsData={listLocationsData}
                toggleUnsavedChangesClose={toggleUnsavedChangesClose}
                onClickCloseAddLocationAndRefresh={
                    onClickCloseAddLocationAndRefresh
                }
                handleCloseAddLocation={() =>
                    handleToggleOverlayState("addLocationOverlay")
                }
                overlayOpen={showOverlayState.addLocationOverlay}
            ></LocationsOverlay>
            <ParentLocationOverlay
                handleCloseParentOverlay={() =>
                    handleToggleOverlayState("parentOverlay")
                }
                onClickOpenScanning={onClickOpenScanningOverlay}
                parentLocationId={parentLocationId}
                onClickOpenEditParent={() =>
                    handleToggleOverlayState("editParentOverlay")
                }
                onClickOpenAddScan={() =>
                    handleToggleOverlayState("addScanningOverlay")
                }
                toggleArchiveModal={toggleArchiveModal}
                overlayOpen={showOverlayState.parentOverlay}
            ></ParentLocationOverlay>
            <ScanningLocationOverlay
                toggleUnsavedChangesCloseBlocker={toggleUnsavedChangesClose}
                toggleUnsavedChangesBackBlocker={toggleUnsavedChangesBack}
                onTriggerBackToParentOverlay={() =>
                    onTriggerBackToParentOverlay(parentLocationId)
                }
                scanningLocationId={scanningLocationId}
                parentLocationId={parentLocationId}
                toggleArchiveScanModal={toggleArchiveScanModal}
                overlayOpen={showOverlayState.scanningOverlay}
                closeScanLocation={() =>
                    handleToggleOverlayState("scanningOverlay")
                }
            ></ScanningLocationOverlay>
            <EditParentLocation
                toggleUnsavedChangesBackBlocker={toggleUnsavedChangesBack}
                toggleUnsavedChangesCloseBlocker={toggleUnsavedChangesClose}
                onTriggerBackToParentOverlay={() =>
                    closeEditParentAndTriggerRefresh(parentLocationId)
                }
                parentLocationId={parentLocationId}
                listParentLocations={
                    listLocationsData && listLocationsData.results
                }
                overlayOpen={showOverlayState.editParentOverlay}
                closeEditParent={() =>
                    handleToggleOverlayState("editParentOverlay")
                }
            ></EditParentLocation>
            <AddScanLocation
                parentLocationId={parentLocationId}
                handleCloseAddScanOverlay={() =>
                    handleToggleOverlayState("addScanningOverlay")
                }
                onTriggerBackToParentOverlay={() =>
                    onTriggerBackToParentOverlay(
                        parentLocationId,
                        "addScanningLocation"
                    )
                }
                overlayOpen={showOverlayState.addScanningOverlay}
                triggerRefresh={triggerRefresh}
            ></AddScanLocation>
        </>
    );
}
