//style
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import {
    createTheme,
    ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
import styled from "styled-components";
import { theme } from "./styling/theme";
import { BrowserRouter as Router } from "react-router-dom";
import "@fontsource/poppins";
import { useMediaQuery } from "react-responsive";

//configs
import configs from "./configs.json";

//amplify
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import AppRoutes from "./components/routes/routes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, Slide } from "react-toastify";
import { COLORS } from "./styling/colors";

//datadog
import DatadogAnalyticsReporter from "./utils/datadogAnalyticsReporter";

//context
import GlobalContext from "./contexts/GlobalContext";
import LocationsContext from "./contexts/LocationsContext";
import AssetsContext from "./contexts/AssetsContext";

//react
import { useEffect, useState } from "react";

//components
import SplashScreen from "./components/routes/SplashScreen";
import Loadable from "./components/common/Loadable";

//utils
import useInitiateSession from "./hooks/useInitiateSession";

DatadogAnalyticsReporter.init();

const StyledToastContainer = styled(ToastContainer)`
    // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
    &&&.Toastify__toast-container {
    }
    .Toastify__toast {
        border-radius: 8px;
    }
    .Toastify__toast-body {
        font-family: poppins;
        font-size: 13px;
        line-height: 20px;
        font-weight: 600;
        letter-spacing: 0.01em;
        color: ${COLORS.DEEP_BLUE_SEA};
    }
    .Toastify__toast-icon {
        align-items: start;
        display: flex;
        flex-direction: column;
    }
    .Toastify__toast--success {
        background-color: ${COLORS.GREEN_4};
    }
    .Toastify__toast-theme--colored.Toastify__toast--warning {
    }
    .Toastify__toast--error {
        background-color: ${COLORS.RED_4};
    }
    .Toastify__progress-bar {
        height: 3px;
    }
`;

//update the header to pass ID token instead of access token to provide
//organizationId to AppSync in the identity context
Amplify.configure(configs.awsConfig, {
    API: {
        GraphQL: {
            headers: async () => {
                const session = await fetchAuthSession();
                return {
                    Authorization: session.tokens.idToken,
                };
            },
        },
    },
});

function App() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
    const isDesktop = useMediaQuery({ minWidth: 1024 });

    const media = {
        isMobile,
        isTablet,
        isDesktop,
    };

    const fullTheme = { ...theme, ...media };

    const muiTheme = createTheme(theme);

    const [isInitialized, setInitialized] = useState(false);

    const [
        initiateSession,
        globalContextDetails,
        locationsContextDetails,
        assetsContextDetails,
    ] = useInitiateSession();

    useEffect(() => {
        initiateSession();
    }, []);

    const globalSelections = globalContextDetails?.globalSelections;
    const updateSelections = globalContextDetails?.updateSelections;

    const locationsSelections = locationsContextDetails?.locationsSelections;
    const updateLocationsSelections =
        locationsContextDetails?.updateLocationsSelections;

    const assetSelections = assetsContextDetails?.assetSelections;
    const updateAssetsSelections = assetsContextDetails?.updateAssetsSelections;

    useEffect(() => {
        if (!globalSelections.isAuthenticated) {
            setTimeout(() => {
                setInitialized(true);
            }, 1500);
        } else if (globalSelections.isAuthenticated) {
            initiateSession();
        }
    }, [globalSelections.isAuthenticated]);

    return (
        <StyledThemeProvider theme={fullTheme}>
            <MUIThemeProvider theme={muiTheme}>
                <StyledToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <GlobalContext.Provider
                    value={{
                        globalSelections,
                        updateSelections,
                    }}
                >
                    <LocationsContext.Provider
                        value={{
                            selections: locationsSelections,
                            updateSelections: updateLocationsSelections,
                        }}
                    >
                        <AssetsContext.Provider
                            value={{
                                selections: assetSelections,
                                updateSelections: updateAssetsSelections,
                            }}
                        >
                            <Router>
                                <Loadable
                                    dataLoaded={isInitialized}
                                    LoadingComponent={SplashScreen}
                                >
                                    <AppRoutes />
                                </Loadable>
                            </Router>
                        </AssetsContext.Provider>
                    </LocationsContext.Provider>
                </GlobalContext.Provider>
            </MUIThemeProvider>
        </StyledThemeProvider>
    );
}

export default App;
