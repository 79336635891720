// external
import React, { useEffect, useState } from "react";
import {
    useSearchParams,
    useLocation,
    useNavigate,
    generatePath,
} from "react-router-dom";

// style
import styled, { useTheme } from "../../styling/styled-components";

// components
import ActivityHome from "./ActivityHome";
import DatePicker from "../common/DatePicker";
import Text from "../common/Text";
import Line from "../common/Line";
import RentalsList from "./RentalsList";
import ScreenView from "../common/ScreenView";
import TextButton from "../common/TextButton";
import LinkText from "../common/LinkText";
import Displayable from "../common/Displayable";

// utils
import { addDays } from "date-fns";

export type SearchTag = {
    value: string;
    label: string;
};

const Container = styled(ScreenView)``;

const ActivityWrapper = styled.div`
    position: relative;
    top: ${({ theme }) => -theme.spacing.small}px;
`;

const DescriptionDiv = styled.div`
    position: relative;
    top: ${({ theme }) => -theme.spacing.xlarge}px;
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

export default function ActivityRentals({ showInfo, ...props }) {
    const { spacing, colors } = useTheme();
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [assetId, setAssetId] = useState(false);
    const [showAssets, setShowAssets] = useState(
        showInfo
            ? location.pathname.includes("rentals")
                ? false
                : true
            : false
    );
    const [changeAssetColor, setChangeAssetColor] = useState(
        showAssets ? colors.DEEP_BLUE_SEA : colors.EXHAUST
    );
    const [changeRentalColor, setChangeRentalColor] = useState(
        !showAssets ? colors.DEEP_BLUE_SEA : colors.EXHAUST
    );
    const [borderColorAsset, setBorderColorAsset] = useState(
        showAssets ? "3px solid #162350" : "0"
    );
    const [borderColorRental, setBorderColorRental] = useState(
        showAssets ? "0" : "3px solid #162350"
    );

    const [dateRange, setDateRange] = useState(
        searchParams.getAll("date") || [null, null]
    );
    const [daysBack, setDaysBack] = useState(
        searchParams.get("daysBack") || -30
    );

    const handleChangeDate = (isRelative, newDaysBack, range) => {
        if (isRelative) {
            setDaysBack(newDaysBack);
            setDateRange([null, null]);
            searchParams.set("daysBack", newDaysBack);
            searchParams.delete("date");
            setSearchParams(searchParams);
        } else {
            setDaysBack(null);
            setDateRange(range);
            searchParams.delete("daysBack");
            searchParams.set("date", range[0]);
            searchParams.append("date", range[1]);
            setSearchParams(searchParams);
        }
    };

    const toggleAsset = (clearSearch = false) => {
        navigate(
            generatePath("/dashboard/activity/assetevents/", {
                assets: encodeURIComponent("assetevents"),
            })
        );
        displayAssets();
        setChangeAssetColor(colors.DEEP_BLUE_SEA);
        setChangeRentalColor(colors.EXHAUST);
        setBorderColorAsset("3px solid #162350");
        setBorderColorRental("0");
    };

    const toggleRentals = (clearSearch = false) => {
        navigate(
            generatePath("/dashboard/activity/rentals/", {
                rentals: encodeURIComponent("rentals"),
            })
        );
        displayRentals();
        setChangeRentalColor(colors.DEEP_BLUE_SEA);
        setChangeAssetColor(colors.EXHAUST);
        setBorderColorRental("3px solid #162350");
        setBorderColorAsset("0");
    };

    const STRINGS = {
        CUSTOM_RANGE_RADIO_LABEL: "customDateRange",
        SELECT_LABEL: "Select a day or date range",
        SUBHEADER_ASSETS: `Each time an asset is scanned at a location, an asset event is logged. A loop opens when a container is checked out; the loop closes when that same container is returned.  These asset events inform key metrics like overall return rate and reuse rate, track the movement of assets, and reflect inventory usage. `,
        SUBHEADER_RENTALS: `Rentals track if and when your consumers return assets within the designated rental window. To learn more about rentals, visit `,
    };

    const descriptionText = () => {
        if (showAssets) {
            return STRINGS.SUBHEADER_ASSETS;
        } else {
            return STRINGS.SUBHEADER_RENTALS;
        }
    };

    const datePickerStyle = {
        marginLeft: "auto",
    };

    const DATE_RANGE_ITEMS = [
        { value: "-1", label: "Last 24 hours", selected: false },
        { value: "-7", label: "Last 7 days", selected: false },
        { value: "-30", label: "Last 30 days", selected: true },
        { value: "-360", label: "Last 12 months", selected: false },
        {
            value: STRINGS.CUSTOM_RANGE_RADIO_LABEL,
            label: STRINGS.SELECT_LABEL,
            selected: false,
        },
    ];

    const onClickDisplay = () => {
        displayAssets();
        toggleAsset();
        setAssetId(true);
    };

    const displayAssets = () => {
        setShowAssets(true);
    };

    const displayRentals = () => {
        setShowAssets(false);
    };

    const onClearSearch = () => {
        setAssetId(false);
    };

    useEffect(() => {
        if (!!daysBack) {
            const today = new Date();
            const daysAgo = addDays(today, daysBack);
            setDateRange([daysAgo, today]);
        }
    }, [daysBack]);

    useEffect(() => {
        if (!daysBack && !dateRange[0]) {
            DATE_RANGE_ITEMS.map((item, index) => {
                if (item.selected) {
                    const today = new Date();
                    const daysAgo = addDays(today, item.value);
                    setDateRange([daysAgo, today]);
                }
            });
        }
    }, [daysBack]);

    return (
        <Container shouldShowCounter={false}>
            <ActivityWrapper>
                <div style={{ display: "flex" }}>
                    <TextButton
                        text="Rentals"
                        onClick={() => toggleRentals({ clearSearch: true })}
                        changeColor={changeRentalColor}
                        changeBorder={borderColorRental}
                    />
                    <TextButton
                        text="Asset events"
                        onClick={() => toggleAsset({ clearSearch: true })}
                        changeColor={changeAssetColor}
                        changeBorder={borderColorAsset}
                    />
                    <DatePicker
                        style={datePickerStyle}
                        processDateChange={handleChangeDate}
                        initDaysBack={daysBack}
                        initDateRange={dateRange}
                        dateRangeItems={DATE_RANGE_ITEMS}
                    />
                </div>
                <Line />
            </ActivityWrapper>
            <DescriptionDiv>
                <Text type="body" size="medium" color={colors.DEEP_BLUE_SEA}>
                    {descriptionText()}
                    <Displayable displayPrimaryComponent={!showAssets}>
                        <LinkText
                            type="new_link"
                            size="small"
                            color={colors.EXHAUST}
                            href={
                                "https://www.topanga.io/reuspass-academy-launch/using-the-dashboard"
                            }
                        >
                            {"ReusePass Academy"}
                        </LinkText>
                    </Displayable>
                </Text>
            </DescriptionDiv>
            {showAssets ? (
                <ActivityHome
                    fromTimestamp={dateRange[0]}
                    toTimestamp={dateRange[1]}
                    assetId={assetId}
                    onClearSearch={onClearSearch}
                />
            ) : (
                <RentalsList
                    fromTimestamp={dateRange[0]}
                    toTimestamp={dateRange[1]}
                    onClickDisplay={onClickDisplay}
                />
            )}
        </Container>
    );
}
