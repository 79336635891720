import React from "react";

//images
import { ReactComponent as Logo } from "../../assets/vectors/Logo.svg";

//styles
import styled from "../../styling/styled-components";
import "@fontsource/lato";

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
}

const StyledSidebarHeader = styled.div`
    height: 64px;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding-left: ${({ theme }) => theme.spacing.medium}px;
    > div {
        width: 100%;
    }
`;

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
    children,
    ...rest
}) => {
    return (
        <StyledSidebarHeader {...rest}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Logo style={{ width: 40, minWidth: 40 }} alt="Topanga.io" />
            </div>
        </StyledSidebarHeader>
    );
};
