//react
import { useEffect, useState, useRef } from "react";

//components
import Text from "./Text";
import DropdownLabel from "./dropdown/DropdownLabel";
import DropdownOption from "./dropdown/DropdownOption";
import Displayable from "./Displayable";
import FlyoutForm from "./dropdown/FlyoutForm";
import Loadable from "./Loadable";

//styling
import styled, { useTheme } from "../../styling/styled-components";

//props
import { FilterOption } from "./Filter";

//utils
import useClickOutside from "../../utils/useClickOutside";

//images
import { ReactComponent as ChevronDown } from "../../assets/vectors/ChevronDown.svg";
import { ReactComponent as ChevronUp } from "../../assets/vectors/ChevronUp.svg";

const DropdownText = styled(Text)`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const DropdownWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    width: 180px;
`;

const IconButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

type Props = {
    options: FilterOption[];
    onClickOption: () => null;
};

export default function TabletNavigationDropdown({
    options,
    onClickOption,
}: Props) {
    const { colors } = useTheme();
    const [popUpOptions, setPopUpOptions] = useState([options]);
    const [isOpen, setIsOpen] = useState(false);
    let textIconColor = isOpen ? colors.BLANK_SLATE : colors.DEEP_BLUE_SEA;
    const selectedOption =
        options.find((item) => item.selected == true) || options[0];

    //refs click outside
    const dropdownRef = useRef("dropdown-button");
    const flyoutRef = useRef("picker-flyout");

    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useClickOutside(
        flyoutRef,
        () => {
            handleToggleDropdown();
        },
        [dropdownRef]
    );

    useEffect(() => {
        if (isOpen) {
            setPopUpOptions(options);
        }
    }, [isOpen]);

    const renderDropdownOptions = () => {
        return (
            <Displayable displayPrimaryComponent={isOpen}>
                <div ref={flyoutRef}>
                    <FlyoutForm borderRadius={"4px"}>
                        <DropdownWrapper>
                            {popUpOptions.map((option, idx) => {
                                return (
                                    <DropdownOption
                                        onClick={() => onClickOption(option)}
                                        key={option?.label}
                                        label={option?.label}
                                        status="default"
                                        textAlign="left"
                                    />
                                );
                            })}
                        </DropdownWrapper>
                    </FlyoutForm>
                </div>
            </Displayable>
        );
    };

    const ChevronUpIcon = () => {
        return <ChevronUp color={colors.BLANK_SLATE} />;
    };

    const DropdownLabelText = () => {
        return (
            <Loadable dataLoaded={options}>
                <IconButtonWrapper>
                    <Displayable displayPrimaryComponent={selectedOption?.Icon}>
                        <selectedOption.Icon color={textIconColor} />
                    </Displayable>
                    <DropdownText
                        type="label"
                        size="small"
                        color={textIconColor}
                    >
                        {selectedOption?.label}
                    </DropdownText>
                    <Displayable
                        displayPrimaryComponent={!isOpen}
                        SecondaryComponent={ChevronUpIcon}
                    >
                        <ChevronDown color={colors.DEEP_BLUE_SEA} />
                    </Displayable>
                </IconButtonWrapper>
            </Loadable>
        );
    };

    return (
        <Loadable dataLoaded={options}>
            <div onClick={handleToggleDropdown} ref={dropdownRef}>
                <DropdownLabel isOpen={isOpen} label={<DropdownLabelText />} />
                {renderDropdownOptions()}
            </div>
        </Loadable>
    );
}
