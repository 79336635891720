//components
import TabletNavigationDropdown from "../../common/TabletNavDropdown";
import { ProductHeader } from "../common/TitleText";
import Displayable from "../../common/Displayable";
import Loadable from "../../common/Loadable";

//react
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//constants
import {
    reusePassTabs,
    products,
    settingsTabs,
    streamLineTabs,
} from "../../../constants/secondaryNav";

//style
import { useTheme } from "../../../styling/styled-components";

export const TabletSecondaryNav = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isTablet } = useTheme();

    const [tabOptions, setTabOptions] = useState([]);
    const [productName, setProductName] = useState("");

    const handleNavigation = (tabItem) => {
        const updatedTabs = tabOptions.map((item) =>
            item.label == tabItem.label
                ? { ...item, selected: true }
                : { ...item, selected: false }
        );
        navigate(tabItem.url);
        setTabOptions(updatedTabs);
    };

    useEffect(() => {
        if (location.pathname) {
            findInitialPath();
        }
    }, []);

    const findInitialPath = () => {
        if (location.pathname.includes("settings")) {
            setProductName("Settings");
            handleInitialNavigation(location.pathname, settingsTabs);
            return;
        }
        if (location.pathname.includes("streamline")) {
            setProductName("StreamLine");
            handleInitialNavigation(location.pathname, streamLineTabs);
            return;
        } else {
            setProductName("ReusePass");
            handleInitialNavigation(location.pathname, reusePassTabs);
            return;
        }
    };

    const handleInitialNavigation = (tabItem, options) => {
        const updatedTabs = options.map((item) =>
            `${item.url.toString().trim()}/` == tabItem.toString().trim()
                ? { ...item, selected: true }
                : { ...item, selected: false }
        );

        setTabOptions(updatedTabs);
    };

    useEffect(() => {
        const productPath = products[location.pathname];
        if (productPath) {
            setTabOptions(productPath.tabOptions);
            setProductName(productPath.productName);
        }
    }, [location.pathname]);

    return (
        <Displayable
            displayPrimaryComponent={
                isTablet && !location.pathname.includes("streamline")
            }
        >
            <ProductHeader productName={productName}>
                <Loadable dataLoaded={tabOptions.length > 0}>
                    <TabletNavigationDropdown
                        options={tabOptions}
                        onClickOption={handleNavigation}
                    />
                </Loadable>
            </ProductHeader>
        </Displayable>
    );
};
