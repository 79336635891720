// react
import React, { useContext, useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";

// API
import { signOut } from "aws-amplify/auth";

// style
import styled, { useTheme } from "styled-components/macro";

// context
import GlobalContext, {
    emptyGlobalSelection,
} from "../../contexts/GlobalContext";

// components
import NeedHelpLink from "./NeedHelpLink";
import Text from "../common/Text";

// constants
import { SIDEBAR } from "../../constants/sidebar";

interface SidebarFooterProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
}

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const UserWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

const SignOutCta = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.small}px 0px;
    padding-right: ${({ theme }) => theme.spacing.small}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    border-top: ${({ theme }) => theme.borderStyle.smallDivider};
    &:hover {
        background-color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
    }
    &:focus {
        outline-color: #BC72E8;
        margin-bottom: ${({ theme }) => theme.spacing.tiny};
        margin-left:  ${({ theme }) => theme.spacing.tiny};
        margin-right:  ${({ theme }) => theme.spacing.small};
    },
`;

const SignOutLabel = styled(Text)`
    cursor: pointer;
    padding-left: ${({ theme }) => theme.spacing.small}px;
`;

function ContainerComponent({ broken, children }) {
    return broken ? (
        <>{children}</>
    ) : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            {children}
        </div>
    );
}

export const SidebarFooter: React.FC<SidebarFooterProps> = ({
    children,
    ...rest
}) => {
    const { colors } = useTheme();
    const context = useContext(GlobalContext);
    const { broken } = useProSidebar();
    const [isSignOutHovered, setIsSignOutHovered] = useState(false);
    const textColor = isSignOutHovered
        ? colors.BLANK_SLATE
        : colors.SUMMER_STORM;
    const navigate = useNavigate();

    const handleSignOut = async () => {
        await signOut();
        context.updateSelections(emptyGlobalSelection);
        navigate("/login");
    };

    const toggleSignOutHoverLink = () => {
        setIsSignOutHovered(!isSignOutHovered);
    };

    const handleSignoutKeyDown = (event) => {
        if (event.key == "Enter") {
            handleSignOut();
        }
    };
    return (
        <ContainerComponent broken={broken}>
            <Container>
                <UserWrapper>
                    <NeedHelpLink />
                    <SignOutCta
                        onKeyDown={handleSignoutKeyDown}
                        onClick={handleSignOut}
                        onMouseEnter={toggleSignOutHoverLink}
                        onMouseLeave={toggleSignOutHoverLink}
                        tabIndex={0}
                    >
                        <SignOutLabel
                            type="label"
                            size="small"
                            color={textColor}
                        >
                            {SIDEBAR.SIGN_OUT}
                        </SignOutLabel>
                    </SignOutCta>
                </UserWrapper>
            </Container>
        </ContainerComponent>
    );
};
