//components
import ClipLoader from "react-spinners/ClipLoader";
import Text from "../common/Text";

//styles
import styled, { useTheme } from "../../styling/styled-components";

const LoadingContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.SMOG};
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

export default function SplashScreen() {
    const colors = useTheme();
    return (
        <LoadingContainer>
            <Text color={colors.DEEP_BLUE_SEA} type="product" size="medium">
                Topanga.io
            </Text>
            <ClipLoader loading={true} />
        </LoadingContainer>
    );
}
