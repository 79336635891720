//style
import styled from "../../../styling/styled-components";

const SelectedWhiteDot = styled.div`
    height: 3px;
    width: 3px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    position: relative;
    bottom: 0px;
`;

const SelectedDotWrapper = styled.div`
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
    margin-right: ${({ theme }) => theme.spacing.xsmall}px;
    margin-top: ${({ theme }) => theme.spacing.small}px;
`;

export const TabSelectedDot = () => {
    return (
        <SelectedDotWrapper>
            <SelectedWhiteDot />
        </SelectedDotWrapper>
    );
};
