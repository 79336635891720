export const LEARN_MORE_URL =
    "https://www.topanga.io/streamline-food-waste-management";

export const OBSERVATION_DETAILS = {
    STATION_LABEL: (stationName: string) =>
        stationName && `Station: ${stationName}`,
};

export const OBSERVATION_PILLS = {
    MULTIPLE_TAGS: "3 tags",
};
