//api
import { ObservationTag } from "../../../API";

//styling
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";

//constants
import { OBSERVATION_PILLS } from "../constants";

const Container = styled.div<{ color: string }>`
    display: flex;
    padding: ${({ theme }) => theme.spacing.tiny}px
        ${({ theme }) => theme.spacing.small}px;
    align-self: flex-start;
    align-items: flex-start;
    background: ${(props) => props.color};
    width: fit-content;
    border: ${(props) => `1px solid ${props.borderColor}`};
    border-radius: 100px;
`;

const LabelText = styled(Text)<{ color: string }>`
    text-align: center;
    color: ${(props) => props.color};
`;

type Props = {
    label: ObservationTag;
    className?: string;
    ignoreLabel?: boolean;
};

type LabelConfig = {
    backgroundColor: string;
    textColor: string;
    copy: string;
    borderColor?: string;
};

export default function ObservationTagPill({
    label,
    className,
    ignoreLabel,
}: Props) {
    const { colors } = useTheme();

    let labelConfig: LabelConfig;
    let backgroundColor = ignoreLabel ? colors.SMOG : colors.ASPHALT;
    let borderColor = ignoreLabel ? colors.ASPHALT : colors.BLANK_SLATE;

    const labelStylingConfig = {
        backgroundColor: backgroundColor,
        textColor: colors.ASH,
        copy: label.charAt(0) + label.slice(1).toLowerCase(),
        borderColor: borderColor,
    };

    switch (label) {
        case ObservationTag.Donation:
        case ObservationTag.Verified:
        case ObservationTag.Trimmings:
        case OBSERVATION_PILLS.MULTIPLE_TAGS:
            labelConfig = labelStylingConfig;
            break;
        default:
            return <></>;
    }

    return (
        <Container
            className={className}
            color={labelConfig.backgroundColor}
            borderColor={labelConfig?.borderColor}
        >
            <LabelText type="label" size="small" color={labelConfig.textColor}>
                {labelConfig.copy}
            </LabelText>
        </Container>
    );
}
