//react
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useEffect } from "react";

//style
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";

//components
import SignedOut from "../sign_in/SignedOut";
import { ProSidebarProvider } from "react-pro-sidebar";
import Dashboard from "../dashboard/Dashboard";
import LoopsList from "../loops/LoopList";
import ConsumerList from "../consumers/ConsumerList";
import ConsumerDetail from "../consumers/ConsumerDetail";
import LoopDetail from "../loops/LoopDetail";
import AssetTypes from "../assets/AssetTypes";
import AssetTypeDetail from "../assets/AssetTypeDetail";
import CreateAssetType from "../assets/CreateAssetType";
import EditAssetType from "../assets/EditAssetType";
import CreateScanAppAccount from "../scanapp/CreateScanAppAccount";
import UpdateScanAppUserSettings from "../scanapp/UpdateScanAppUserSettings";
import UpdateScanAppUserPassword from "../scanapp/UpdateScanAppUserPassword";
import ProtectedLayout from "./ProtectedLayout";
import PageNotFound from "../overlay/PageNotFound";
import ActivityRentals from "../activity/ActivityRentals";
import ProgramSettings from "../settings/ProgramSettings";
import StreamLineLanding from "../streamline/StreamLineLanding";

//amplify
import { getCurrentUser } from "aws-amplify/auth";

// utils
import DatadogAnalyticsReporter from "../../utils/datadogAnalyticsReporter";
import { randomToken } from "../../utils/helpers";

export enum ROUTES {
    FORGOT_PASSWORD = "forgot-password",
}

export default function Component() {
    const navigate = useNavigate();

    const PublicRoute = ({ children }) => {
        async function checkAuthState() {
            try {
                await getCurrentUser();
                navigate("dashboard/overview");
            } catch (err) {}
        }
        useEffect(() => {
            checkAuthState();
        });
        return children;
    };

    return (
        <ProSidebarProvider>
            <Routes>
                <Route
                    index
                    path="login/*"
                    element={
                        <PublicRoute>
                            <SignedOut />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/"
                    element={
                        <PublicRoute>
                            <Navigate to="/login" />
                        </PublicRoute>
                    }
                />
                <Route path="dashboard" element={<ProtectedLayout />}>
                    <Route index path="overview" element={<Dashboard />} />
                    <Route path="assets">
                        <Route index element={<AssetTypes />} />
                        <Route path=":assetTypeId">
                            <Route index element={<AssetTypeDetail />} />
                            <Route path="edit" element={<EditAssetType />} />
                        </Route>
                        <Route path="new" element={<CreateAssetType />} />
                    </Route>
                    <Route path="loops">
                        <Route index element={<LoopsList />} />
                        <Route path=":loopId" element={<LoopDetail />} />
                    </Route>
                    <Route path="activity">
                        <Route
                            index
                            element={<ActivityRentals showInfo={false} />}
                        ></Route>
                        <Route
                            index
                            path="rentals/"
                            element={<ActivityRentals showInfo={false} />}
                        ></Route>
                        <Route
                            index
                            path="assetevents/"
                            element={<ActivityRentals showInfo={true} />}
                        ></Route>
                    </Route>
                    <Route path="consumers">
                        <Route index element={<ConsumerList />} />
                        <Route
                            path=":consumerId"
                            element={<ConsumerDetail />}
                        />
                    </Route>

                    <Route
                        path="settings/scan-app/create"
                        element={<CreateScanAppAccount />}
                    />
                    <Route
                        path="settings/scan-app/:userId/settings"
                        element={<UpdateScanAppUserSettings />}
                    />
                    <Route
                        path="settings/scan-app/:userId/password"
                        element={<UpdateScanAppUserPassword />}
                    />
                    <Route path="settings" element={<ProgramSettings />}>
                        <Route path="locations" element={<ProgramSettings />} />
                        <Route
                            path="scan-app"
                            element={<ProgramSettings index={3} />}
                        ></Route>
                        <Route
                            path="rentals"
                            element={<ProgramSettings />}
                        ></Route>
                    </Route>
                    <Route path="streamline" element={<StreamLineLanding />}>
                        <Route
                            index
                            path="activity"
                            element={<StreamLineLanding />}
                        />
                    </Route>
                    <Route path="streamline" element={<StreamLineLanding />}>
                        <Route
                            index
                            path="observations"
                            element={<StreamLineLanding />}
                        />
                    </Route>
                </Route>
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </ProSidebarProvider>
    );
}
