import React, { useState, useEffect } from "react";
import useGraphClient from "../../hooks/useGraphClient";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import DeprecatedText from "../common/DeprecatedText";
import Button from "../common/Button";
import ScreenView from "../common/ScreenView";
import ScanAppUserLocationSettings from "./ScanAppUserLocationSettings";
import styled from "styled-components/macro";
import { randomToken } from "../../utils/helpers";
import { ScanAppUserSettings } from "../../API";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { findTextStyles } from "../../styling/styled-components";
import { API_ERROR } from "../../constants/errors";
import { UPDATE_SCAN_APP_USER_SETTINGS } from "../../constants/scanapp";
import BackHeader from "../common/BackHeader";
import ConfirmationModal from "../common/ConfirmationModal";
import { toastError, toastSuccess } from "../../utils/toasts";

const StyledScreenView = styled(ScreenView)``;

const StyledSaveButton = styled(Button)``;

const StyledScanAppUserLocationSettings = styled(ScanAppUserLocationSettings)``;

const HeaderWrapper = styled.div`
    padding-top: 0px;
    padding-bottom: ${({ theme }) => theme.spacing.xlarge}px;
`;

const CharcoalText = styled(DeprecatedText)`
    ${({ theme, type, size }) => findTextStyles(type, size)}
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const ContentWrapper = styled.div`
    width: 610px;
    margin: 0 auto;
`;

export default function UpdateScanAppUserSettings() {
    const [user, setUser] = useState<ScanAppUserSettings>();
    const [selectedLocationId, setSelectedLocationId] = useState<string>();
    const [allLocationsEnabled, setAllLocationsEnabled] =
        useState<boolean>(false);
    const [enabledLocationIds, setEnabledLocationIds] = useState<string[]>([]);
    const [resetLocationsKey, setResetLocationsKey] = useState<string>();
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [changesMade, setChangesMade] = useState<boolean>(false);
    const [getUserLoading, setGetUserLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const navigate = useNavigate();
    const { userId } = useParams();

    const graphClient = useGraphClient();

    useEffect(() => {
        if (userId) {
            getUser(userId);
        }
    }, [userId]);

    useEffect(() => {
        let changed = false;
        if (user) {
            if (
                selectedLocationId &&
                selectedLocationId != user.selectedLocationId
            ) {
                changed = true;
            }
            if (
                !user.allLocationsEnabled &&
                enabledLocationIds != user.enabledLocationIds
            ) {
                changed = true;
            }
            if (user.allLocationsEnabled != allLocationsEnabled) {
                changed = true;
            }
        }
        setChangesMade(changed);
    }, [selectedLocationId, enabledLocationIds, allLocationsEnabled]);

    const getUser = async (userId: string): null => {
        setGetUserLoading(true);
        try {
            const results = await graphClient.graphql({
                query: queries.getScanAppUser,
                variables: {
                    userId,
                },
            });

            const user = results?.data?.getScanAppUser;
            setSelectedLocationId(user.selectedLocationId);
            setEnabledLocationIds(user.enabledLocationIds);
            setAllLocationsEnabled(user.allLocationsEnabled);
            setUser(user);
            setGetUserLoading(false);
        } catch (err) {
            setGetUserLoading(false);
            console.error("Error getting ScanApp user", err);
            toastError(API_ERROR);
        }
    };

    const handleSave = async (): null => {
        setSaveLoading(true);
        try {
            await graphClient.graphql({
                query: mutations.updateScanAppUserSettings,
                variables: {
                    input: {
                        userId,
                        selectedLocationId,
                        enabledLocationIds: allLocationsEnabled
                            ? []
                            : enabledLocationIds,
                        allLocationsEnabled,
                    },
                },
            });
            setSaveLoading(false);
            toastSuccess(UPDATE_SCAN_APP_USER_SETTINGS.SAVE_MESSAGE);
            navigate("/dashboard/settings/scan-app");
            resetForm();
        } catch (err) {
            setSaveLoading(false);
            console.error("Error updating ScanApp location settings", err);
            toastError(API_ERROR);
        }
    };

    const cancelEdit = (): null => {
        resetForm();
        navigate("/dashboard/settings/scan-app");
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        if (changesMade) {
            openModal();
        } else {
            cancelEdit();
        }
    };

    const resetForm = (): null => {
        setChangesMade(false);
        setAllLocationsEnabled();
        setEnabledLocationIds([]);
        setSelectedLocationId();
    };

    return (
        <StyledScreenView
            shouldShowCounter={false}
            title={
                <BackHeader
                    onClick={handleCancel}
                    label={UPDATE_SCAN_APP_USER_SETTINGS.CANCEL}
                />
            }
            headerRight={
                <StyledSaveButton
                    type={changesMade ? "primary" : "secondary"}
                    status={"default"}
                    label={
                        changesMade
                            ? UPDATE_SCAN_APP_USER_SETTINGS.SAVE
                            : UPDATE_SCAN_APP_USER_SETTINGS.DONE
                    }
                    onClick={changesMade ? handleSave : handleCancel}
                    isLoading={saveLoading}
                />
            }
        >
            <ConfirmationModal
                isOpen={isModalOpen}
                title={UPDATE_SCAN_APP_USER_SETTINGS.CANCEL_TITLE}
                subtitle={UPDATE_SCAN_APP_USER_SETTINGS.CANCEL_SUBTITLE}
                onConfirm={cancelEdit}
                onCancel={closeModal}
            />
            <ContentWrapper>
                <HeaderWrapper>
                    <CharcoalText type="header" size="large">
                        {UPDATE_SCAN_APP_USER_SETTINGS.HEADER}
                    </CharcoalText>
                    <CharcoalText type="header" size="medium">
                        {UPDATE_SCAN_APP_USER_SETTINGS.USERNAME(user?.username)}
                    </CharcoalText>
                </HeaderWrapper>
                <StyledScanAppUserLocationSettings
                    enabledLocationIds={enabledLocationIds}
                    allLocationsEnabled={allLocationsEnabled}
                    selectedLocationId={selectedLocationId}
                    onEnabledLocationIdsChange={(locations) =>
                        setEnabledLocationIds(locations)
                    }
                    onSelectedLocationIdChange={(locationId) =>
                        setSelectedLocationId(locationId)
                    }
                    onAllLocationsEnabledChange={(enabled) =>
                        setAllLocationsEnabled(enabled)
                    }
                />
            </ContentWrapper>
        </StyledScreenView>
    );
}
