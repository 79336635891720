// style
import styled, {
    useTheme,
    findTextStyles,
} from "../../styling/styled-components";
import { TEXT_STYLES } from "../../styling";
import { COLORS } from "../../styling";

interface TextButtonProps {
    size: keyof typeof TEXT_STYLES.body &
        keyof typeof TEXT_STYLES.header &
        keyof typeof TEXT_STYLES.other &
        keyof typeof TEXT_STYLES.link;
    type: keyof typeof TEXT_STYLES;
    href?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    children?: JSX.Element | string | string[] | null;
    ariaLabel?: string;
    color?: string;
}

const CommonButton = styled.button`
    ${({ type, size }) => findTextStyles(type, size)};
    background: none;
    border: none;
    padding: 0;
    white-space: nowrap;
`;

const DarkButton = styled(CommonButton)`
    color: ${(props) => props.color};
    cursor: pointer;
    text-decoration-line: underline;

    &:hover {
        color: ${({ theme }) => theme.colors.PLASTIC_BOTTLE};
        text-decoration-color: ${({ theme }) => theme.colors.PLASTIC_BOTTLE};
        text-decoration-line: underline;
    }

    &:disabled {
        color: ${({ theme }) => theme.colors.SUMMER_STORM};
        text-decoration-line: none;
`;

const LightButton = styled(CommonButton)`
    color: ${({ theme }) => theme.colors.BLANK_SLATE};
    cursor: pointer;
    text-decoration-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    text-decoration-line: underline;

    &:hover {
        color: ${({ theme }) => theme.colors.PLASTIC_BOTTLE};
        text-decoration-color: ${({ theme }) => theme.colors.PLASTIC_BOTTLE};
    }

    &:disabled {
        color: ${({ theme }) => theme.colors.SUMMER_STORM};
        text-decoration-line: none;
    }
`;

const handleKeyPress = (e, onClick) => {
    if (e.key === "Enter" || e.key === " ") {
        onClick();
        e.preventDefault();
    }
};

export default function LinkText({
    size,
    type,
    children = null,
    disabled = false,
    onClick = null,
    href = null,
    ariaLabel = null,
    color = COLORS.WAVE_STORM,
}: TextButtonProps) {
    onClick = generateClickHandlerFunction(href, onClick);

    return (
        <DarkButton
            size={size}
            type={type}
            disabled={disabled}
            onClick={onClick}
            data-override-outside-click={true}
            onKeyPress={(e) => handleKeyPress(e, onClick)}
            aria-label={ariaLabel}
            tabIndex="0"
            role="link"
            color={color}
        >
            {children}
        </DarkButton>
    );
}

export function LightLinkText({
    size,
    type,
    children = null,
    disabled = false,
    onClick = null,
    href = null,
    ariaLabel = null,
}: TextButtonProps) {
    onClick = generateClickHandlerFunction(href, onClick);

    return (
        <LightButton
            size={size}
            type={type}
            disabled={disabled}
            onClick={onClick}
            data-override-outside-click={true}
            onKeyPress={(e) => handleKeyPress(e, onClick)}
            aria-label={ariaLabel}
            tabIndex="0"
            role="link"
        >
            {children}
        </LightButton>
    );
}

function generateClickHandlerFunction(href, onClick) {
    if (!!href) {
        return () => {
            window.open(href, "_blank");
        };
    }
    return onClick;
}
