//images
import { ReactComponent as StreamLine } from "../../assets/vectors/StreamLineLogo.svg";

//styling
import styled from "../../styling/styled-components";

//components
import { TabSelectedDot } from "./common/TabSelectedDot";
import { HoverLabelText } from "./common/HoverLabelText";
import Displayable from "../common/Displayable";

//react
import { useLocation } from "react-router-dom";

//constants
import { SIDEBAR } from "../../constants/sidebar";
import { Component } from "react";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: ${({ theme }) => theme.spacing.large}px;
`;

const SelectedWrapper = styled.div`
    margin-left: ${({ theme }) => theme.spacing.xsmall}px;
    position: relative;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
`;

type Props = {
    StreamLineIcon: Component;
    displayHoverLabel: boolean;
};
export const StreamLineActiveIcon = ({
    StreamLineIcon,
    displayHoverLabel,
}: Props) => {
    const location = useLocation();
    return (
        <Displayable
            displayPrimaryComponent={location.pathname.includes("streamline")}
            SecondaryComponent={StreamLineIcon}
        >
            <Wrapper>
                <StreamLine />
                <SelectedWrapper>
                    <TabSelectedDot />
                </SelectedWrapper>
                <Displayable displayPrimaryComponent={displayHoverLabel}>
                    <HoverLabelText label={SIDEBAR.STREAMLINE} />
                </Displayable>
            </Wrapper>
        </Displayable>
    );
};
