//components
import LinkText from "../../../common/LinkText";
import Text from "../../../common/Text";
import { ClipLoader } from "react-spinners";
import Loadable from "../../../common/Loadable";

//styling
import styled, { useTheme } from "../../../../styling/styled-components";

//api
import useGraphClient from "../../../../hooks/useGraphClient";
import * as mutations from "../../../../graphql/mutations";

//react
import { useState } from "react";

const STRINGS = {
    IGNORE: "Ignore observation",
    DONT_IGNORE: "Don't ignore observation",
    DONT_IGNORE_DESCRIPTION:
        "This observation has been ignored. It will not be included in any reporting data. ",
    IGNORE_DESCRIPTION:
        "This observation is included in reporting data. To exclude it, ignore the observation. This action is reversible. ",
};

const IgnoreWrapper = styled.div`
    display: flex;
    padding-bottom: ${({ theme }) => theme.spacing.xlarge}px;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
`;

const LinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

const IgnoreText = styled(LinkText)``;

const SpinnerWrapper = styled.div`
    padding-right: ${({ theme }) => theme.spacing.tiny}px;
`;

type Props = {
    observationId: string;
    ignoreTagPresent: boolean;
    toggleIgnoreObservation: () => null;
};

export default function ObservationIgnoreToggle({
    observationId,
    ignoreTagPresent,
    toggleIgnoreObservation,
}: Props) {
    const { colors } = useTheme();
    const graphClient = useGraphClient();
    const [ignoreLoading, setIgnoreLoading] = useState(false);

    const mimicLoadingState = () => {
        setTimeout(() => {
            setIgnoreLoading(false);
        }, 10000);
    };

    const addIgnoreTag = async (): null => {
        try {
            const results = await graphClient.graphql({
                query: mutations.addObservationTags,
                variables: {
                    input: {
                        observationId: observationId,
                        tags: ["IGNORE"],
                    },
                },
            });
            const data = results?.data.addObservationTags;
            if (data.includes("IGNORE")) {
                setIgnoreLoading(true);
                toggleIgnoreObservation();
                mimicLoadingState();
            }
        } catch (err) {
            console.error("Error adding ignore tag", err);
        }
    };

    const removeIgnoreTag = async (): null => {
        try {
            const results = await graphClient.graphql({
                query: mutations.removeObservationTags,
                variables: {
                    input: {
                        observationId: observationId,
                        tags: ["IGNORE"],
                    },
                },
            });
            const data = results?.data.removeObservationTags;
            if (!data.includes("IGNORE")) {
                setIgnoreLoading(true);
                toggleIgnoreObservation();
                mimicLoadingState();
            }
        } catch (err) {
            console.error("Error removing ignore tag", err);
        }
    };

    const toggleIgnoreTag = ignoreTagPresent ? removeIgnoreTag : addIgnoreTag;
    const ignoreText = ignoreTagPresent ? STRINGS.DONT_IGNORE : STRINGS.IGNORE;
    const ignoreDescription = ignoreTagPresent
        ? STRINGS.DONT_IGNORE_DESCRIPTION
        : STRINGS.IGNORE_DESCRIPTION;
    const linkColor = ignoreLoading ? colors.EXHAUST : colors.WAVE_STORM;

    return (
        <IgnoreWrapper>
            <Text type={"label"} size={"medium"} color={colors.DEEP_BLUE_SEA}>
                {ignoreDescription}
                <LinkWrapper>
                    <IgnoreText
                        type={"new_link"}
                        size={"small"}
                        onClick={toggleIgnoreTag}
                        color={linkColor}
                        disabled={ignoreLoading}
                    >
                        {ignoreText}
                    </IgnoreText>
                    <Loadable dataLoaded={ignoreLoading}>
                        <SpinnerWrapper>
                            <ClipLoader size={12} color={colors.WAVE_STORM} />
                        </SpinnerWrapper>
                    </Loadable>
                </LinkWrapper>
            </Text>
        </IgnoreWrapper>
    );
}
