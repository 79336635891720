// style
import styled, { useTheme, css } from "../../../styling/styled-components";

// components
import Text from "../Text";
import { ClipLoader } from "react-spinners";
import Displayable from "../Displayable";

const OptionWrapper = styled.div`
    text-align: ${(props) => props.textAlign};
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.xsmall}px;
    ${({ theme }) => theme.spacing.xsmall}px;
    padding-left: ${({ theme }) => theme.spacing.medium}px;
    padding-right: ${({ theme }) => theme.spacing.medium}px;
    white-space: nowrap;
    &:focus-visible {
        outline-color: #bc72e8;
    }
    ${({ theme }) =>
        css`
            &:hover {
                ${(props) =>
                    props.status == "default"
                        ? `background-color: ${theme.colors.SEAGLASS};   cursor: pointer;`
                        : ``};
            }
        `};
`;

const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

type Props = {
    label: string;
    onClick: () => void;
    key: string;
    status: string;
    textAlign: string;
};

export default function DropdownOption({
    label,
    onClick,
    key,
    status,
    textAlign = "right",
    ...props
}: Props) {
    const { colors } = useTheme();
    const labelColor =
        status == "default" ? colors.WAVE_STORM : colors.MARINE_LAYER;

    const LoadingIcon = () => {
        return (
            <IconWrapper>
                <ClipLoader size={16} color={colors.WAVE_STORM} />
                <Text type="header" size="small" color={labelColor}>
                    {label}
                </Text>
            </IconWrapper>
        );
    };

    return (
        <OptionWrapper
            onClick={onClick}
            key={key}
            status={status}
            textAlign={textAlign}
            tabIndex={0}
        >
            <Displayable
                displayPrimaryComponent={status == "default"}
                SecondaryComponent={LoadingIcon}
            >
                <Text type="label" size="medium" color={labelColor}>
                    {label}
                </Text>
            </Displayable>
        </OptionWrapper>
    );
}
