//style
import styled, { useTheme } from "../../../styling/styled-components";

//components
import Text from "../../common/Text";
import VerticalLine from "../../common/VerticalLine";
import Displayable from "../../common/Displayable";

//react
import { useContext } from "react";
import GlobalContext from "../../../contexts/GlobalContext";
import { useLocation } from "react-router-dom";

const HeaderDiv = styled.div`
    display: flex;
    height: 78px;
    padding: 0px ${({ theme }) => theme.spacing.xxlarge}px;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.large}px;
    flex-shrink: 0;
    border-radius: 0px 0px 4px 4px;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: ${({ theme }) => theme.shadowStyle.containerDropShadow};
    position: absolute;
    top: 0px;
    z-index: 200;
    ${({ theme }) =>
        theme.isTablet || theme.isMobile ? "width: 83%;" : "width: 89%;"}
`;

const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.tiny}px;
`;

const UserNameWrapper = styled.div`
    margin-left: auto;
`;

export const ProductHeader = ({ productName, children }) => {
    const { colors } = useTheme();
    const context = useContext(GlobalContext);
    const { organizationName, username } = context.globalSelections;
    const location = useLocation();

    return (
        <HeaderDiv>
            <TitleDiv>
                <Text type="product" size="small" color={colors.DEEP_BLUE_SEA}>
                    {productName}
                </Text>
                <Text type="body" size="medium" color={colors.EXHAUST}>
                    {organizationName}
                </Text>
            </TitleDiv>
            <VerticalLine height="60%" />
            {children}
            <Displayable
                displayPrimaryComponent={location.pathname.includes("settings")}
            >
                <UserNameWrapper>
                    <Text type="body" size="medium" color={colors.EXHAUST}>
                        {username}
                    </Text>
                </UserNameWrapper>
            </Displayable>
        </HeaderDiv>
    );
};
