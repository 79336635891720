import { COLORS } from "./colors";
import { fontWeightsPoppins2, fontWeightsPoppins1 } from "../styling/tokens";
import {
    dashboardTypeStylesProductName,
    dashboardTypeStylesLabelMediumBold12,
    fontFamiliesLato,
    fontSize9,
} from "../styling/tokens";

const LETTER_SPACING = {
    tiny: "0.01em",
    small: -0.32,
    medium: -1,
};

const BODY_TEXT_STYLES = {
    xlarge: `
    font-family: poppins;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: ${LETTER_SPACING.tiny};
    color: ${COLORS.SUMMER_STORM};
`,
    large: `
        font-family: poppins;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
    `,
    medium: `
        font-family: poppins;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
    `,
    small: `
        font-family: poppins;
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
`,
    tiny: `
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
    `,
};

const HEADER_TEXT_STYLES = {
    xlarge: `
        font-family: poppins;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
    `,
    large: `
        font-family: poppins;
        font-size: 18px;
        line-height: 27px;
        font-weight: 500;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
    `,
    medium: `
        font-family: poppins;
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
    `,
    small: `
        font-family: poppins;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
    `,
    smallest: `
        font-family: poppins;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
    `,
    little: `
    font-family: poppins;
    font-style: normal;
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: ${LETTER_SPACING.tiny};
    color: ${COLORS.EXHAUST};
`,
    tiny: `
font-family: poppins;
font-size: 10px;
line-height: 15px;
font-weight: 400;
letter-spacing: ${LETTER_SPACING.tiny};
color: ${COLORS.SUMMER_STORM};
`,
};

const OTHER_TEXT_STYLES = {
    xlarge: `
    font-family: poppins;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: ${LETTER_SPACING.tiny};
    color: ${COLORS.SUMMER_STORM};
`,
    large: `
        font-family: poppins;
        font-size: 13px;
        line-height: 20px;
        font-weight: 600;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
    `,
    medium: `
        font-family: poppins;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
    `,
    small: `
        font-family: poppins;
        font-size: 11px;
        line-height: 16px;
        font-weight: 500;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.SUMMER_STORM};
    `,
    tiny: `
    font-family: poppins;
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    letter-spacing: ${LETTER_SPACING.tiny};
    color: ${COLORS.SUMMER_STORM};
`,
};

const LINK_TEXT_STYLES = {
    xlarge: `
    font-family: poppins;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: ${LETTER_SPACING.tiny};
    color: ${COLORS.SUMMER_STORM};
`,
    large: `
        font-family: poppins;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.WAVE_STORM};
        underline-width: 1;
        text-decoration-line: underline;
        text-decoration-color: ${COLORS.WAVE_STORM};
    `,
    medium: `
        font-family: poppins;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.WAVE_STORM};
        underline-width: 1;
        text-decoration-line: underline;
        text-decoration-color: ${COLORS.WAVE_STORM};
    `,
    small: `
        font-family: poppins;
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.WAVE_STORM};
        underline-width: 1;
        text-decoration-line: underline;
        text-decoration-color: ${COLORS.WAVE_STORM};
    `,
    tiny: `
    font-family: poppins;
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    letter-spacing: ${LETTER_SPACING.tiny};
    color: ${COLORS.SUMMER_STORM};
`,
};

const LABEL_BOLD = {
    tiny: ``,
    small: ``,
    medium: `
        font-family: poppins;
        font-size: 12px;
        line-height: auto;
        font-weight: 700;
        letter-spacing: ${LETTER_SPACING.tiny};
        color: ${COLORS.WAVE_STORM};
    `,
    large: ``,
};

const NEW_LINK_STYLES = {
    large: `
   font-family: poppins;
   font-size: 13px;
   line-height: 20px;
   font-weight: ${fontWeightsPoppins1};
   letter-spacing: ${LETTER_SPACING.tiny};
   color: ${COLORS.SUMMER_STORM};
`,
    medium: `
    font-family: poppins;   
    font-size: 12px;
    line-height: 18px;
    font-weight: ${fontWeightsPoppins2};
    letter-spacing: ${LETTER_SPACING.tiny};
    color: ${COLORS.SUMMER_STORM};
`,
    small: `
    font-family: poppins;
    font-size: 11px;
    line-height: 15px;
    font-weight: ${fontWeightsPoppins2};
    letter-spacing: ${LETTER_SPACING.tiny};
    color: ${COLORS.SUMMER_STORM};
`,
    tiny: `
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: ${fontWeightsPoppins2};
    line-height: normal;
    letter-spacing: ${LETTER_SPACING.tiny};
    color: ${COLORS.SUMMER_STORM};
`,
};

const PRODUCT_STYLE = {
    tiny: ``,
    small: `${dashboardTypeStylesProductName}`,
    medium: `    
    font-family: ${fontFamiliesLato};
    font-style: normal;
    font-size: ${fontSize9};
    line-height: normal;
    font-weight:  ${fontWeightsPoppins1};
    letter-spacing: ${LETTER_SPACING.medium};
`,
    large: ``,
};

export const TEXT_STYLES = {
    body: BODY_TEXT_STYLES,
    header: HEADER_TEXT_STYLES,
    label: OTHER_TEXT_STYLES,
    other: OTHER_TEXT_STYLES,
    link: LINK_TEXT_STYLES,
    new_link: NEW_LINK_STYLES,
    product: PRODUCT_STYLE,
    label_bold: LABEL_BOLD,
};
