//images
import { ReactComponent as ReusePassLogo } from "../../assets/vectors/ReusePassLogo.svg";

//style
import styled from "../../styling/styled-components";

//component
import { TabSelectedDot } from "./common/TabSelectedDot";
import { HoverLabelText } from "./common/HoverLabelText";
import Displayable from "../common/Displayable";
import { useLocation } from "react-router-dom";

//react
import { Component } from "react";

const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: ${({ theme }) => theme.spacing.small}px;
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const Selectedwrapper = styled.div`
    margin-left: ${({ theme }) => theme.spacing.xsmall}px;
    position: relative;
`;

type Props = {
    ReusePassIcon: Component;
    displayHoverLabel: boolean;
};

export const ReusePassActiveIcon = ({
    ReusePassIcon,
    displayHoverLabel,
}: Props) => {
    const location = useLocation();

    const activePaths =
        location.pathname.includes("overview") ||
        location.pathname.includes("activity") ||
        location.pathname.includes("assets") ||
        location.pathname.includes("consumers");

    return (
        <Displayable
            displayPrimaryComponent={activePaths}
            SecondaryComponent={ReusePassIcon}
        >
            <IconWrapper>
                <ReusePassLogo />
                <Selectedwrapper>
                    <TabSelectedDot />
                </Selectedwrapper>
                <Displayable displayPrimaryComponent={displayHoverLabel}>
                    <HoverLabelText label="ReusePass" />
                </Displayable>
            </IconWrapper>
        </Displayable>
    );
};
