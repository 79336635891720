//react
import React from "react";

//style
import styled, { useTheme } from "../../styling/styled-components";

//components
import Text from "../common/Text";
import NewLine from "../common/NewLine";

//constants
import { LOCATIONS_TEXT } from "../settings/ProgramSettings";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.small}px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xlarge}px;
    flex: 1 0 0;
`;

const DescriptionWrapper = styled.div`
    flex: 1 0 0;
`;

type Props = {
    address?: string;
    googlePlacesId?: number | string;
    nickname?: string;
};

export default function LocationScreenHeader({}: Props) {
    const { colors } = useTheme();

    return (
        <Wrapper>
            <HeaderWrapper>
                <Text color={colors.ASH} type="header" size="large">
                    Locations
                </Text>
            </HeaderWrapper>
            <NewLine />
            <DescriptionWrapper>
                <Text color={colors.ASH} type="body" size="medium">
                    {LOCATIONS_TEXT.DESCRIPTION}
                </Text>
            </DescriptionWrapper>
        </Wrapper>
    );
}
