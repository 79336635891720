//amplify
import { getCurrentUser } from "aws-amplify/auth";

//style
import { useMediaQuery } from "react-responsive";
import styled from "styled-components/macro";

//react
import { useEffect, useRef, useContext } from "react";
import { useOutlet, useNavigate, useLocation } from "react-router-dom";

//components
import { useProSidebar } from "react-pro-sidebar";
import NavSidebar from "../nav/Sidebar";
import MobileScreen from "./MobileScreen";
import MobileSidebar from "../nav/MobileSidebar";
import { HeaderSecondaryNav } from "../nav/secondaryNav/HeaderSecondaryNav";
import { TabletSecondaryNav } from "../nav/secondaryNav/TabletSecondaryNav";
import Loadable from "../common/Loadable";
import SplashScreen from "./SplashScreen";

//context
import GlobalContext from "../../contexts/GlobalContext";

type Props = {
    dashboardInitiated: boolean;
};

const Container = styled.div`
    display: flex;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: visible;
`;

interface NonSideBarProps {
    collapsed: boolean;
    toggled: boolean;
}

const NonSideBarContainer = styled.div<NonSideBarProps>`
    height: 100vh;
    overflow-y: scroll;
    width: -webkit-fill-available;
    width: -moz-available;
    ${({ collapsed, toggled }) =>
        collapsed && toggled ? "position: absolute;" : ""}
`;

const ChildrenContainer = styled.div`
    height: -webkit-fill-available;
`;

export default function ProtectedLayout({}: Props) {
    const outlet = useOutlet();
    const { pathname } = useLocation();
    const childrenContainerRef = useRef(null);
    const navigate = useNavigate();
    const { collapsed, toggled, broken } = useProSidebar();
    const context = useContext(GlobalContext);
    const { organizationName } = context.globalSelections;

    async function checkAuthState() {
        try {
            await getCurrentUser();
        } catch (err) {
            navigate("/login");
        }
    }
    useEffect(() => {
        checkAuthState();
    });

    useEffect(() => {
        childrenContainerRef.current?.scrollIntoView({
            behavior: "auto",
            block: "start",
        });
    }, [pathname]);

    const isMobile = useMediaQuery({ maxWidth: 767 });

    if (isMobile) {
        return <MobileScreen />;
    }

    return (
        <Loadable dataLoaded={organizationName} LoadingComponent={SplashScreen}>
            <Container>
                <NavSidebar />
                {broken && <MobileSidebar />}
                <NonSideBarContainer toggled={toggled} collapsed={collapsed}>
                    <ChildrenContainer ref={childrenContainerRef}>
                        <HeaderSecondaryNav />
                        <TabletSecondaryNav />
                        {outlet}
                    </ChildrenContainer>
                </NonSideBarContainer>
            </Container>
        </Loadable>
    );
}
