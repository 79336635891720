//components
import Text from "../../../common/Text";

//styling
import styled, { useTheme } from "../../../../styling/styled-components";

const Headers = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const HeaderText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const LocationText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const CapturedText = styled(Text)`
    color: ${({ theme }) => theme.colors.EXHAUST};
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
`;

type HeaderDetails = {
    weight: string;
    value: string;
    ignoreFlag: string;
    label: string;
    location: string;
    station: string;
    capturedOn: string;
};

type Props = {
    headerDetails: HeaderDetails;
};

export default function ObservationHeader({ headerDetails }: Props) {
    const { colors } = useTheme();

    return (
        <Headers>
            <HeaderText type="header" size="large">
                {headerDetails.label}
            </HeaderText>
            <HeaderText type="header" size="small">
                {headerDetails.weight} {headerDetails.value}
            </HeaderText>
            <LocationText type="header" size="small">
                {headerDetails.location}
            </LocationText>
            <CapturedText type="body" size="medium" color={colors.EXHAUST}>
                {headerDetails.capturedOn}
                <b> {headerDetails.ignoreFlag}</b>
            </CapturedText>
        </Headers>
    );
}
