//component
import { Component } from "react";
import Displayable from "../../common/Displayable";
import { HoverLabelText } from "./HoverLabelText";

type Props = {
    Logo: Component;
    displayHoverLabel: boolean;
    label: string;
};

export const SidebarIcon = ({ Logo, displayHoverLabel, label }: Props) => {
    return (
        <>
            <Logo />
            <Displayable displayPrimaryComponent={displayHoverLabel}>
                <HoverLabelText label={label} />
            </Displayable>
        </>
    );
};
