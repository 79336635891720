// react
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// API
import useGraphClient from "../../hooks/useGraphClient";
import * as mutations from "../../graphql/mutations";
import { ScanAppUserAccountType } from "../../API";

// style
import styled from "styled-components/macro";

// components
import AccountTypeToggle from "./AccountTypeToggle";
import BackHeader from "../common/BackHeader";
import Button from "../common/Button";
import FormTextField from "../common/FormTextField";
import ScanAppUserLocationSettings from "./ScanAppUserLocationSettings";
import ScreenView from "../common/ScreenView";
import Text from "../common/Text";

// context
import GlobalContext from "../../contexts/GlobalContext";

// utils
import { isPasswordInvalid, validateEmail } from "../../utils/validation";
import { randomToken } from "../../utils/helpers";
import { toastError, toastSuccess } from "../../utils/toasts";
import { graphErrorDetails } from "../../utils/helpers";

//constants
import { API_ERROR } from "../../constants/errors";
import { CREATE_SCAN_APP_ACCOUNT } from "../../constants/scanapp";

const PASSWORD_MIN_LENGTH = 8;

const StyledScreenView = styled(ScreenView)`
    display: flex;
    height: auto;
`;

const StyledSaveButton = styled(Button)`
    align-self: flex-start;
    margin-left: 0;
    margin-top: 12px;
`;

const HeaderWrapper = styled.div`
    padding: 0px 0 ${({ theme }) => theme.spacing.large}px;
`;

const AccountTypeWrapper = styled.div`
    max-width: 360px;
`;

const StyledAccountTypeToggle = styled.div`
    margin: ${({ theme }) => theme.spacing.small}px 0px;
`;

const StyledScanAppUserLocationSettings = styled(ScanAppUserLocationSettings)`
    padding-top: ${({ theme }) => theme.spacing.huge}px;
`;

const CredsWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.xlarge}px;
`;

const CredsHeader = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    max-width: 720px;
`;

const CredsDivider = styled.div`
    padding: ${({ theme }) => theme.spacing.medium}px 0px;
`;

const Section = styled.div`
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xxlarge}px;
    margin: 3px;
`;

const StyledTextInput = styled(FormTextField)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

const CharcoalText = styled(Text)`
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const AshText = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 610px;
`;

export default function CreateScanAppAccount() {
    const [userAccountType, setUserAccountType] = useState<string>(
        ScanAppUserAccountType.Group
    );
    const [formComplete, setFormComplete] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [usernameError, setUsernameError] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [selectedLocationId, setSelectedLocationId] = useState<string>("");
    const [allLocationsEnabled, setAllLocationsEnabled] = useState<boolean>(
        userAccountType == ScanAppUserAccountType.Individual
    );
    const [enabledLocationIds, setEnabledLocationIds] = useState<string[]>([]);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [resetLocationsKey, setResetLocationsKey] = useState<string>();
    const [counterInteraction, setCounterInteraction] = useState<number>(0);
    const navigate = useNavigate();
    const { slug } = useContext(GlobalContext).globalSelections;
    const usernamePrefix = `${slug}.`;

    const graphClient = useGraphClient();

    useEffect(() => {
        setAllLocationsEnabled(
            userAccountType == ScanAppUserAccountType.Individual
        );
    }, [userAccountType]);

    useEffect(() => {
        let complete = false;
        if (userAccountType == ScanAppUserAccountType.Individual) {
            complete = !!email && !!selectedLocationId && validateEmail(email);
            setFormComplete(complete);
        }
        if (userAccountType == ScanAppUserAccountType.Group) {
            complete =
                password.length >= PASSWORD_MIN_LENGTH &&
                !!username &&
                !!selectedLocationId;
            setFormComplete(complete);
        }
    }, [userAccountType, email, username, password, selectedLocationId]);

    const validateCreds = (): boolean => {
        let isValid = true;

        if (userAccountType == ScanAppUserAccountType.Individual) {
            if (!validateEmail(email)) {
                setEmailError(CREATE_SCAN_APP_ACCOUNT.EMAIL_INVALID);
                isValid = false;
            }
            return isValid;
        }
        if (userAccountType == ScanAppUserAccountType.Group) {
            if (password.length < PASSWORD_MIN_LENGTH) {
                setPasswordError(CREATE_SCAN_APP_ACCOUNT.PASSWORD_ERROR);
                isValid = false;
            }
            return isValid;
        }
    };

    const validatePassword = (): boolean => {
        if (
            password.length < PASSWORD_MIN_LENGTH ||
            isPasswordInvalid(password)
        ) {
            console.log("hmmmm");
            setPasswordError(CREATE_SCAN_APP_ACCOUNT.PASSWORD_ERROR);
        }
        return (
            !isPasswordInvalid(password) &&
            password.length > PASSWORD_MIN_LENGTH
        );
    };

    const resetErrors = () => {
        setEmailError("");
        setPasswordError("");
        setUsernameError("");
    };

    const resetForm = () => {
        setUsername("");
        setEmail("");
        setPassword("");
        setSelectedLocationId("");
        setAllLocationsEnabled(
            userAccountType == ScanAppUserAccountType.Individual
        );
        setEnabledLocationIds([]);
        setResetLocationsKey(randomToken());
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setEmail(text);
        setCounterInteraction((counterInteraction) => counterInteraction + 1);
        if (validateEmail(email)) {
            setEmailError("");
        }
    };

    const handleUsernameChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setUsername(
            text == slug || text == usernamePrefix
                ? ""
                : text.replace(new RegExp(`(^${usernamePrefix})`, "g"), "")
        );
        setUsernameError("");
        setCounterInteraction((counterInteraction) => counterInteraction + 1);
    };

    const handlePasswordChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const text = event.target.value;
        setPassword(text);
        setPasswordError("");
        setCounterInteraction((counterInteraction) => counterInteraction + 1);
    };

    const handleAccountTypeChange = (accountType: ScanAppUserAccountType) => {
        setUserAccountType(accountType);
    };

    const handleCancel = () => {
        resetForm();
        navigate("/dashboard/settings/scan-app");
    };

    const handleSave = async () => {
        if (validateCreds()) {
            setSaveLoading(true);
            try {
                let createdUserId = "";
                if (userAccountType == ScanAppUserAccountType.Individual) {
                    const results = await graphClient.graphql({
                        query: mutations.createScanAppIndividualUser,
                        variables: {
                            input: {
                                email,
                                settings: {
                                    allLocationsEnabled,
                                    enabledLocationIds: allLocationsEnabled
                                        ? []
                                        : enabledLocationIds,
                                    selectedLocationId,
                                    canChangeResolutions: false,
                                    selectedResolutionIds: [],
                                },
                            },
                        },
                    });
                    createdUserId =
                        results.data.createScanAppIndividualUser.userId;
                } else {
                    const results = await graphClient.graphql({
                        query: mutations.createScanAppGroupUser,
                        variables: {
                            input: {
                                username,
                                password,
                                settings: {
                                    allLocationsEnabled,
                                    enabledLocationIds: allLocationsEnabled
                                        ? []
                                        : enabledLocationIds,
                                    selectedLocationId,
                                    canChangeResolutions: false,
                                    selectedResolutionIds: [],
                                },
                            },
                        },
                    });
                    createdUserId = results.data.createScanAppGroupUser.userId;
                }
                setSaveLoading(false);
                toastSuccess(CREATE_SCAN_APP_ACCOUNT.SAVE_MESSAGE);
                navigate("/dashboard/settings/scan-app");
                resetForm();
            } catch (err) {
                const [getErrorType, _getErrorMessage] = graphErrorDetails(
                    err?.errors
                );
                setSaveLoading(false);
                if ("UsernameExistsException" == getErrorType) {
                    toastError(CREATE_SCAN_APP_ACCOUNT.USERNAME_TAKEN);
                    setUsernameError(CREATE_SCAN_APP_ACCOUNT.USERNAME_TAKEN);
                    setEmailError(CREATE_SCAN_APP_ACCOUNT.USERNAME_TAKEN);
                } else if ("InvalidPasswordException" == getErrorType) {
                    toastError(CREATE_SCAN_APP_ACCOUNT.PASSWORD_ERROR);
                    setPasswordError(CREATE_SCAN_APP_ACCOUNT.PASSWORD_ERROR);
                } else {
                    console.error("Error creating ScanApp user", err);
                    toastError(API_ERROR);
                }
            }
        }
    };

    return (
        <StyledScreenView
            shouldShowCounter={false}
            title={
                <BackHeader
                    onClick={handleCancel}
                    label={CREATE_SCAN_APP_ACCOUNT.CANCEL}
                />
            }
        >
            <ContentWrapper>
                <HeaderWrapper>
                    <CharcoalText type="header" size="large">
                        {CREATE_SCAN_APP_ACCOUNT.HEADER}
                    </CharcoalText>
                </HeaderWrapper>
                <AccountTypeWrapper>
                    <CharcoalText type="header" size="medium">
                        {CREATE_SCAN_APP_ACCOUNT.ACCOUNT_TYPE}
                    </CharcoalText>
                    <StyledAccountTypeToggle>
                        <AccountTypeToggle
                            onChange={handleAccountTypeChange}
                            selectedType={userAccountType}
                        />
                    </StyledAccountTypeToggle>
                    <CharcoalText type="body" size="medium">
                        {userAccountType == ScanAppUserAccountType.Individual
                            ? CREATE_SCAN_APP_ACCOUNT.INDIVIDUAL_ACCOUNT_TYPE_HELP
                            : CREATE_SCAN_APP_ACCOUNT.GROUP_ACCOUNT_TYPE_HELP}
                    </CharcoalText>
                </AccountTypeWrapper>
                <CredsWrapper>
                    <CredsHeader>
                        {userAccountType ==
                        ScanAppUserAccountType.Individual ? (
                            <CharcoalText type="header" size="smallest">
                                {CREATE_SCAN_APP_ACCOUNT.INDIVIDUAL_CREDENTIALS}
                            </CharcoalText>
                        ) : (
                            <>
                                <CharcoalText type="header" size="smallest">
                                    {
                                        CREATE_SCAN_APP_ACCOUNT.GROUP_ACCOUNT_CREDENTIALS
                                    }
                                </CharcoalText>
                                <AshText type="body" size="medium">
                                    {
                                        CREATE_SCAN_APP_ACCOUNT.GROUP_ACCOUNT_CREDENTIALS_HELP
                                    }
                                </AshText>
                            </>
                        )}
                    </CredsHeader>
                    {userAccountType == ScanAppUserAccountType.Individual ? (
                        <Section>
                            <StyledTextInput
                                value={email}
                                onChange={handleEmailChange}
                                onBlur={() => {
                                    if (email.length && !validateEmail(email)) {
                                        setEmailError(
                                            CREATE_SCAN_APP_ACCOUNT.EMAIL_INVALID
                                        );
                                    } else {
                                        setEmailError("");
                                    }
                                }}
                                placeholder={
                                    CREATE_SCAN_APP_ACCOUNT.EMAIL_PLACEHOLDER
                                }
                                hasError={!!emailError}
                                maxLength={100}
                                secureTextEntry={false}
                                autoCorrect={false}
                                autoCapitalize="none"
                                returnKeyType="done"
                                hintText={CREATE_SCAN_APP_ACCOUNT.EMAIL}
                                error={emailError}
                                infoText={
                                    CREATE_SCAN_APP_ACCOUNT.TEMP_PASSWORD_HELP
                                }
                                showCounter={false}
                            />
                        </Section>
                    ) : (
                        <Section>
                            <StyledTextInput
                                value={`${usernamePrefix}${username}`}
                                onChange={handleUsernameChange}
                                placeholder={CREATE_SCAN_APP_ACCOUNT.USERNAME_PLACEHOLDER(
                                    slug
                                )}
                                hasError={!!usernameError}
                                maxLength={50}
                                secureTextEntry={false}
                                autoCorrect={false}
                                autoCapitalize="none"
                                returnKeyType="done"
                                hintText={CREATE_SCAN_APP_ACCOUNT.USERNAME}
                                error={usernameError}
                                subHintText={CREATE_SCAN_APP_ACCOUNT.USERNAME_HELP(
                                    slug
                                )}
                                showCounter={false}
                            />
                            <CredsDivider />
                            <StyledTextInput
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder={
                                    CREATE_SCAN_APP_ACCOUNT.PASSWORD_PLACEHOLDER
                                }
                                onBlur={validatePassword}
                                hasError={!!passwordError}
                                maxLength={40}
                                secureTextEntry={false}
                                autoCorrect={false}
                                autoCapitalize="none"
                                returnKeyType="done"
                                hintText={CREATE_SCAN_APP_ACCOUNT.PASSWORD}
                                error={passwordError}
                                subHintText={
                                    CREATE_SCAN_APP_ACCOUNT.PASSWORD_HELP
                                }
                                showCounter={false}
                            />
                        </Section>
                    )}
                </CredsWrapper>
                <StyledScanAppUserLocationSettings
                    key={resetLocationsKey}
                    enabledLocationIds={enabledLocationIds}
                    allLocationsEnabled={allLocationsEnabled}
                    selectedLocationId={selectedLocationId}
                    onEnabledLocationIdsChange={(locations) =>
                        setEnabledLocationIds(locations)
                    }
                    onSelectedLocationIdChange={(locationId) =>
                        setSelectedLocationId(locationId)
                    }
                    onAllLocationsEnabledChange={(enabled) =>
                        setAllLocationsEnabled(enabled)
                    }
                    updateMode={false}
                />
                <StyledSaveButton
                    type={"primary"}
                    status={formComplete ? "default" : "disabled"}
                    label={CREATE_SCAN_APP_ACCOUNT.SAVE}
                    onClick={handleSave}
                    isLoading={saveLoading}
                />
            </ContentWrapper>
        </StyledScreenView>
    );
}
