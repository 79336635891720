//style
import styled from "../../../styling/styled-components";

const ButtonWrap = styled.div`
    position: absolute;
    bottom: ${({ theme }) => theme.spacing.huge}px;
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    width: 100%;
    flex-shrink: 0;
    background-color: ${({ theme }) => theme.colors.BLANK_SLATE};
    z-index: 2000;
    box-shadow: 10px 0px 16px 0px rgba(0, 0, 0, 0.05);
    ${(props) =>
        props.height == "true"
            ? `height: ${props.theme.spacing.small}%`
            : `height: ${props.theme.spacing.medium}%`}
`;

const ButtonContainer = styled.div``;

export function ButtonDiv({ children, height = "false" }) {
    return (
        <ButtonContainer>
            <ButtonWrap height={height}>{children}</ButtonWrap>
        </ButtonContainer>
    );
}
