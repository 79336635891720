//context
import {
    emptyGlobalSelection,
    GlobalSelections,
} from "../contexts/GlobalContext";
import {
    emptyLocationsSelections,
    LocationsSelections,
} from "../contexts/LocationsContext";
import {
    emptyAssetSelections,
    AssetSelections,
} from "../contexts/AssetsContext";

//apis
import * as queries from "../graphql/queries";
import useGraphClient from "./useGraphClient";

//react
import { useEffect, useState } from "react";

//amplify
import { getCurrentUser } from "aws-amplify/auth";
import { fetchUserAttributes } from "aws-amplify/auth";

//datadog
import DatadogAnalyticsReporter from "../utils/datadogAnalyticsReporter";

export default function useInitiateSession() {
    const graphClient = useGraphClient();

    const [sessionInitiated, setSessionInitiated] = useState(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    const [globalSelections, setGlobalSelections] =
        useState<GlobalSelections>(emptyGlobalSelection);
    const [locationsSelections, setLocationsSelections] =
        useState<LocationsSelections>(emptyLocationsSelections);
    const [assetSelections, setAssetSelections] =
        useState<AssetSelections>(emptyAssetSelections);

    // Global context
    const updateSelections = (selections: GlobalSelections) => {
        setGlobalSelections({ ...globalSelections, ...selections });
    };

    // Locations context
    const updateLocationsSelections = (selections: LocationsSelections) => {
        setLocationsSelections({ ...locationsSelections, ...selections });
    };

    // Assets context
    const updateAssetsSelections = (selections: AssetSelections) => {
        setAssetSelections({ ...assetSelections, ...selections });
    };

    const globalContextDetails = {
        updateSelections: updateSelections,
        globalSelections: globalSelections,
    };

    const locationsContextDetails = {
        updateLocationsSelections: updateLocationsSelections,
        locationsSelections: locationsSelections,
    };

    const assetsContextDetails = {
        updateAssetsSelections: updateAssetsSelections,
        assetSelections: assetSelections,
    };

    useEffect(() => {
        if (!globalSelections.isAuthenticated) {
            setSessionInitiated(false);
        } else if (globalSelections.isAuthenticated) {
            setLoading(false);
            initiateSession();
        }
    }, [globalSelections.isAuthenticated]);

    const initiateSession = async (): void => {
        try {
            if (!isLoading || !sessionInitiated) {
                // Will throw error if user is not authed
                await getCurrentUser();
                // If user is authed; get attributes
                const userAttributes = await fetchUserAttributes();

                try {
                    setLoading(true);
                    const [organizationResult, locationResults, assetResults] =
                        await Promise.all([
                            graphClient.graphql({
                                query: queries.getOrganization,
                            }),
                            graphClient.graphql({
                                query: queries.listLocations,
                            }),
                            graphClient.graphql({
                                query: queries.listAssetTypes,
                            }),
                        ]);

                    updateSelections({
                        username: userAttributes.email,
                        userAdmin: userAttributes.email.includes("@topanga.io"),
                        organizationName:
                            organizationResult.data.getOrganization.name,
                        slug: organizationResult.data.getOrganization.slug,
                        scanAppUsers:
                            organizationResult.data.getOrganization
                                .scanAppUsers,
                        scanAppUserLimit:
                            organizationResult.data.getOrganization
                                .scanAppUserLimit,
                    });

                    updateLocationsSelections({
                        locations:
                            locationResults.data?.listLocations?.results || [],
                        isInitialized: true,
                    });

                    updateAssetsSelections({
                        assets:
                            assetResults.data?.listAssetTypes?.results || [],
                        isInitialized: true,
                    });

                    DatadogAnalyticsReporter.setUser(userAttributes.email);
                    DatadogAnalyticsReporter.setOrganization(
                        organizationResult.data.getOrganization.slug
                    );

                    setSessionInitiated(true);
                    setLoading(false);
                } catch (err) {
                    console.error("Error initiating session", err);
                    setLoading(false);
                    setSessionInitiated(false);
                }
            }
        } catch (_err) {
            // user needs to authenticate
            setLoading(false);
            setSessionInitiated(false);
        }
    };

    return [
        initiateSession,
        globalContextDetails,
        locationsContextDetails,
        assetsContextDetails,
    ];
}
