//components
import AccordionLabel from "../../../common/accordion/AccordionLabel";
import Displayable from "../../../common/Displayable";

//api
import { ImageObservation } from "../../../../API";

//styling
import styled, { useTheme } from "../../../../styling/styled-components";

const STRINGS = {
    IMAGES: (count: number) => `IMAGES (${count})`,
    open: "Show fewer images",
    default: "Show more images",
    NO_IMAGES: "No additional images",
};

const ImagesWrapper = styled.div``;

const StyledImage = styled.img`
    width: 444px;
    height: 335px;
    flex-shrink: 0;
    border-radius: 4px;
    cursor: pointer;
    padding: 0px 0px ${({ theme }) => theme.spacing.medium}px 0px;
`;

type Props = {
    images: ImageObservation[];
    observationId: string;
};

export default function ObservationImages({ images, observationId }: Props) {
    const { colors } = useTheme();
    // FIXIT: remove when [FTK-87] is released
    const imagesDeduped = [
        ...new Map(images.map((image) => [image.key, image])).values(),
    ];

    return (
        <ImagesWrapper>
            <Displayable displayPrimaryComponent={imagesDeduped?.length > 0}>
                <StyledImage
                    src={imagesDeduped[0]?.url}
                    key={`${imagesDeduped[0]?.key}-${new Date()}`}
                />
            </Displayable>
            <Displayable displayPrimaryComponent={imagesDeduped?.length > 1}>
                <AccordionLabel
                    accordionText={STRINGS}
                    overlayId={observationId}
                >
                    {(imagesDeduped || [])
                        .slice(1)
                        .map((image: ImageObservation) => (
                            <StyledImage
                                src={image.url}
                                key={`${image.key}-${new Date()}`}
                            />
                        ))}
                </AccordionLabel>
            </Displayable>
        </ImagesWrapper>
    );
}
