//components
import Text from "../../../common/Text";

//styling
import styled, { useTheme } from "../../../../styling/styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.MARINE_LAYER};
`;

const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const DetailColumn = styled.div`
    text-align: left;
    flex: 1;
`;

const ValueColumn = styled.div`
    text-align: right;
    flex: 1;
`;

type TableDetails = {
    detail: string;
    value: string;
};

type Props = {
    tableDetails: TableDetails[];
};

export default function ObservationDetailsTable({ tableDetails }: Props) {
    const { colors } = useTheme();

    return (
        <Container>
            {tableDetails.map((item, index) => {
                return (
                    <RowWrapper>
                        <DetailColumn>
                            <Text
                                type="body"
                                size="medium"
                                color={colors.EXHAUST}
                            >
                                {item.detail}
                            </Text>
                        </DetailColumn>
                        <ValueColumn>
                            <Text
                                type="label"
                                size="medium"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {item.value}
                            </Text>
                        </ValueColumn>
                    </RowWrapper>
                );
            })}
        </Container>
    );
}
