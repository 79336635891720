// style
import styled, { useTheme } from "../../styling/styled-components";

// images
import { ReactComponent as HelpCircle } from "../../assets/vectors/HelpCircle.svg";
import { ReactComponent as HelpCircleSummerStorm } from "../../assets/vectors/HelpCircleSummerStorm.svg";

// components
import Text from "../common/Text";
import Displayable from "../common/Displayable";

//react
import { useState } from "react";

//constants
import { SIDEBAR } from "../../constants/sidebar";

const TextWithIconContainer = styled.div`
    align-items: center;
    display: flex;
    padding-bottom: ${({ theme }) => theme.spacing.tiny}px;
`;

const HelpLinkContainer = styled.div`
    display: flex;
    width: 72px;
    height: 60px;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.tiny}px;
    flex-shrink: 0;
    cursor: pointer;
    padding-top: ${({ theme }) => theme.spacing.small}px;
    &:hover {
        color: ${({ theme }) => theme.colors.BLANK_SLATE};
        background-color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
    }
    &:focus {
        outline-color: #BC72E8;
        margin-bottom: ${({ theme }) => theme.spacing.tiny};
        margin-left:  ${({ theme }) => theme.spacing.tiny};
        margin-right:  ${({ theme }) => theme.spacing.large};
    },
`;

const DividerDiv = styled.div`
    display: flex;
    height: 24px;
`;

export default function NeedHelpLink() {
    const { colors } = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const textColor = isHovered ? colors.BLANK_SLATE : colors.SUMMER_STORM;

    const handleOpenTopangaSuport = () => {
        window.open("https://www.topanga.io/post/dashboard-support");
    };

    const toggleHoverState = () => {
        setIsHovered(!isHovered);
    };

    const handleKeyDownOpenTopangaSupport = (event) => {
        if (event.key == "Enter") {
            handleOpenTopangaSuport();
        }
    };

    return (
        <>
            <HelpLinkContainer
                onMouseEnter={toggleHoverState}
                onMouseLeave={toggleHoverState}
                onClick={handleOpenTopangaSuport}
                tabIndex={0}
                onKeyDown={handleKeyDownOpenTopangaSupport}
            >
                <TextWithIconContainer>
                    <Displayable
                        displayPrimaryComponent={!isHovered}
                        SecondaryComponent={HelpCircle}
                    >
                        <HelpCircleSummerStorm />
                    </Displayable>
                </TextWithIconContainer>
                <Text type="label" size="small" color={textColor}>
                    {SIDEBAR.HELP}
                </Text>
            </HelpLinkContainer>
            <DividerDiv />
        </>
    );
}
