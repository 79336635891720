//react
import { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

//context
import GlobalContext from "../../contexts/GlobalContext";

//styles
import styled, { useTheme } from "../../styling/styled-components";

//components
import ScreenView from "../common/ScreenView";
import LearnMore from "./LearnMore";
import ObservationsLog from "./observations/ObservationsLog";
import Text from "../common/Text";
import TextButton from "../common/TextButton";
import DatePicker from "../common/DatePicker";
import EventSearch, { SearchTag } from "../common/EventSearch";
import DonationsFilter from "./filters/DonationsFilter";
import CheckBox from "./filters/CheckBox";
import ObservationOverlay from "./observations/overlay/ObservationOverlay";

// utils
import { addDays } from "date-fns";
import { ObservationTag } from "../../API";

const STRINGS = {
    ACTIVITY: "Activity",
    SELECT_DATE_LABEL: "Select a day or date range",
    COUNT_RECORDS: (count: number | string) => `${count} results`,
    OBSERVATION_LOG: "Observation log",
    LABELED_FILTER: "Hide unlabeled",
    IGNORE_FILTER: "Hide ignored",
};

const DATE_RANGE_ITEMS = [
    { value: "-1", label: "Last 24 hours", selected: false },
    { value: "-7", label: "Last 7 days", selected: true },
    { value: "-30", label: "Last 30 days", selected: false },
    { value: "-360", label: "Last 12 months", selected: false },
    {
        value: "customDateRange",
        label: STRINGS.SELECT_DATE_LABEL,
        selected: false,
    },
];

const ROWS_PER_PAGE = 50;

const SEARCH_TAGS: SearchTag[] = [{ value: "id", label: "ObservationID" }];

/*These are the orgs that are ungated from seeing StreamLine*/
const SLUG_WHITELIST: string[] = [
    // staging
    "faber",
    // production
    "topanga-supply",
    "ohio-state",
    "appstate",
    "vandy",
];

const Container = styled(ScreenView)`
    background-color: ${({ theme }) => theme.colors.SMOG};
`;

const Tabs = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom-color: ${({ theme }) => theme.colors.SUMMER_STORM};
    border-bottom-width: 0.5px;
    border-bottom-style: solid;
    margin-bottom: 30px;
`;

const StyledEventSearch = styled(EventSearch)`
    width: 500px;
`;

const RecordsText = styled(Text)`
    text-align: right;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const Options = styled.div``;

const OptionRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 18px;
`;

const OptionGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
`;

const OptionRowOne = styled(OptionRow)``;
const OptionRowTwo = styled(OptionRow)``;

export default function StreamLineLanding() {
    const { colors } = useTheme();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    // tab selections
    const [showActivty, setShowActivty] = useState(true);

    // search params to tag filters
    const [includeTags, setIncludeTags] = useState<ObservationTag[]>([]);
    const [excludeTags, setExcludeTags] = useState<ObservationTag[]>([]);

    const donations = searchParams.get("donations");
    const labeledOnly = searchParams.get("labeled") || "TRUE";
    const hideIgnoreObservations = searchParams.get("ignore") || "TRUE";

    useEffect(() => {
        let updatedIncludeTags = includeTags;
        let updatedExcludeTags = excludeTags;

        if (donations === null) {
            updatedIncludeTags = updatedIncludeTags.filter(
                (tag) => tag !== ObservationTag.Donation
            );
            updatedExcludeTags = updatedExcludeTags.filter(
                (tag) => tag !== ObservationTag.Donation
            );
        }

        if (donations === "TRUE") {
            updatedExcludeTags = updatedExcludeTags.filter(
                (tag) => tag !== ObservationTag.Donation
            );
            updatedIncludeTags.push(ObservationTag.Donation);
        }

        if (donations === "FALSE") {
            updatedIncludeTags = updatedIncludeTags.filter(
                (tag) => tag !== ObservationTag.Donation
            );
            updatedExcludeTags.push(ObservationTag.Donation);
        }

        if (labeledOnly === null || labeledOnly === "FALSE") {
            updatedIncludeTags = updatedIncludeTags.filter(
                (tag) => tag !== ObservationTag.Labeled
            );
        }

        if (labeledOnly === "TRUE") {
            updatedIncludeTags.push(ObservationTag.Labeled);
        }
        if (hideIgnoreObservations === "FALSE") {
            updatedExcludeTags = updatedExcludeTags.filter(
                (tag) => tag !== ObservationTag.Ignore
            );
        }
        if (hideIgnoreObservations === "TRUE") {
            updatedExcludeTags.push(ObservationTag.Ignore);
        }

        setIncludeTags(updatedIncludeTags);
        setExcludeTags(updatedExcludeTags);
    }, [donations, labeledOnly, hideIgnoreObservations]);

    //ignore observations table refresh
    const [triggerIgnoreRefresh, setTriggerIgnoreRefresh] = useState(false);

    const triggerRefreshAfterToggleIgnore = () => {
        setTriggerIgnoreRefresh(!triggerIgnoreRefresh);
    };

    //Locations
    const [locationIds, setLocationIds] = useState(
        searchParams.getAll("location")
    );
    const locationIdsFromParams = searchParams.get("locationIds");

    useEffect(() => {
        if (locationIdsFromParams) {
            setLocationIds(locationIdsFromParams.split(","));
        } else {
            setLocationIds([]);
        }
    }, [locationIdsFromParams]);

    // count of records
    const [countRecords, setCountRecords] = useState<string | number>("-");

    // pagination
    const [paginateFromObservationIdx, setPaginateFromObservationIdx] =
        useState(0);

    const page = searchParams.get("page");

    useEffect(() => {
        const pageNumber = Number(page);
        if (pageNumber >= 1) {
            setPaginateFromObservationIdx((pageNumber - 1) * ROWS_PER_PAGE);
        }
    }, [page]);

    // date picker
    const [dateRange, setDateRange] = useState(
        searchParams.getAll("date") || [null, null]
    );
    const [parsedDateRange, setParsedDateRange] = useState<[Date?, Date?]>([
        undefined,
        undefined,
    ]);
    const [daysBack, setDaysBack] = useState(
        searchParams.get("daysBack") || -7
    );

    useEffect(() => {
        try {
            let fromDate = dateRange[0] && new Date(dateRange[0]);
            let toDate = dateRange[1] && new Date(dateRange[1]);

            if (!fromDate || !toDate) {
                toDate = new Date();
                fromDate = addDays(toDate, daysBack);
            }
            setParsedDateRange([fromDate, toDate]);
        } catch (_err) {
            console.error("Error parsing date range from search params.");
        }
    }, [daysBack, dateRange]);

    // search
    const eventSearch = useRef(null);
    const [searchTerm] = useState(searchParams.get("searchQuery"));

    useEffect(() => {
        location.pathname.includes("activity") && setShowActivty(true);
    }, [location.pathname]);

    // TEMP: This is temporary mechanism to block content
    const { slug } = useContext(GlobalContext).globalSelections;
    if (!SLUG_WHITELIST.includes(slug)) {
        return (
            <Container shouldShowCounter={false}>
                <LearnMore />
            </Container>
        );
    }
    //ENDTEMP

    const handleChangeDate = (
        isRelative: boolean,
        newDaysBack: string | number,
        range: [Date, Date]
    ) => {
        if (isRelative) {
            setDaysBack(newDaysBack);
            setDateRange([null, null]);
            searchParams.set("daysBack", newDaysBack.toString());
            searchParams.delete("date");
            setSearchParams(searchParams);
        } else {
            setDaysBack(null);
            setParsedDateRange(range);
            searchParams.delete("daysBack");
            searchParams.set("date", range[0]);
            searchParams.append("date", range[1]);
            setSearchParams(searchParams);
        }
    };

    return (
        <Container shouldShowCounter={false}>
            <ObservationOverlay
                observationId={searchParams.get("oid")}
                onClose={() => {
                    searchParams.delete("oid");
                    setSearchParams(searchParams);
                }}
                refreshAfterToggleIgnore={triggerRefreshAfterToggleIgnore}
            />
            <Tabs>
                {/*TODO: this common TextButton component needs work*/}
                <TextButton
                    text={STRINGS.OBSERVATION_LOG}
                    onClick={() => null}
                    changeColor={colors.DEEP_BLUE_SEA}
                    changeBorder={"3px solid #162350"}
                />
            </Tabs>
            <Options>
                <OptionRowOne>
                    <StyledEventSearch
                        onChange={(term) => {
                            if (term) {
                                searchParams.set("searchQuery", term);
                                setSearchParams(searchParams);
                            }
                        }}
                        clearSearchTerm={() => {
                            searchParams.delete("searchQuery");
                            setSearchParams(searchParams);
                        }}
                        searchTags={SEARCH_TAGS}
                        tagRows={[1]}
                        value={searchTerm}
                        ref={eventSearch}
                        showTags={true}
                    />
                    <DatePicker
                        processDateChange={handleChangeDate}
                        initDaysBack={daysBack}
                        initDateRange={dateRange}
                        dateRangeItems={DATE_RANGE_ITEMS}
                    />
                </OptionRowOne>
                <OptionRowTwo>
                    <OptionGroup>
                        <DonationsFilter
                            isDonationSelected={
                                donations === "TRUE"
                                    ? true
                                    : donations === "FALSE"
                                    ? false
                                    : null
                            }
                            onClear={() => {
                                searchParams.delete("donations");
                                setSearchParams(searchParams);
                            }}
                            onSave={(options) => {
                                const isDonationSelected = options.some(
                                    (option) =>
                                        option.selected &&
                                        option.value === "YES"
                                );
                                searchParams.set(
                                    "donations",
                                    isDonationSelected ? "TRUE" : "FALSE"
                                );
                                setSearchParams(searchParams);
                            }}
                        />
                        <CheckBox
                            isSelected={labeledOnly === "TRUE"}
                            onChange={(isSelected) => {
                                searchParams.set(
                                    "labeled",
                                    isSelected ? "TRUE" : "FALSE"
                                );
                                setSearchParams(searchParams);
                            }}
                            label={STRINGS.LABELED_FILTER}
                        />
                        <CheckBox
                            isSelected={hideIgnoreObservations === "TRUE"}
                            onChange={(isSelected) => {
                                searchParams.set(
                                    "ignore",
                                    isSelected ? "TRUE" : "FALSE"
                                );
                                setSearchParams(searchParams);
                            }}
                            label={STRINGS.IGNORE_FILTER}
                        />
                    </OptionGroup>
                    <RecordsText type="label" size="medium">
                        {STRINGS.COUNT_RECORDS(countRecords)}
                    </RecordsText>
                </OptionRowTwo>
            </Options>
            {showActivty && (
                <ObservationsLog
                    fromTimestamp={parsedDateRange[0]}
                    toTimestamp={parsedDateRange[1]}
                    parentLocationIds={locationIds}
                    searchQuery={searchParams.get("searchQuery")}
                    includeTags={includeTags}
                    excludeTags={excludeTags}
                    paginateFrom={paginateFromObservationIdx}
                    size={ROWS_PER_PAGE}
                    onSelectObservationId={(id) => {
                        searchParams.set("oid", id);
                        setSearchParams(searchParams);
                    }}
                    onResultCountChange={(count) => setCountRecords(count)}
                    onPageChange={(page) => {
                        searchParams.set("page", page);
                        setSearchParams(searchParams);
                    }}
                    triggerIgnoreRefresh={triggerIgnoreRefresh}
                />
            )}
        </Container>
    );
}
