//style
import styled, { useTheme } from "../../../styling/styled-components";

//common
import Text from "../../common/Text";

const HoverText = styled(Text)`
    position: relative;
    z-index: 100000;
    background-color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
`;

const TooltipWrapper = styled.div`
    position: absolute;
    top: 58px;
    right: 6px;
`;

export const HoverLabelText = ({ label }: string) => {
    const { colors } = useTheme();
    return (
        <TooltipWrapper>
            <HoverText type="label" size="small" color={colors.MARINE_LAYER}>
                {label}
            </HoverText>
        </TooltipWrapper>
    );
};
