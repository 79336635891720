//react
import { useEffect, useState } from "react";

//api
import { observation } from "../../../../graphql/queries";
import { useQuery } from "../../../../graphql/hooks/query";

//components
import Overlay from "../../../common/overlay/Overlay";
import PageNotFound from "../../../overlay/PageNotFound";
import ObservationImages from "./ObservationImages";
import ObservationIgnoreToggle from "./ObservationIgnoreToggle";
import ObservationTagPill from "../ObservationTagPill";
import Text from "../../../common/Text";
import ObservationHeader from "./ObservationHeader";
import ObservationDetailsTable from "./ObservationDetailsTable";

//styling
import styled, { useTheme } from "../../../../styling/styled-components";

//utils
import { parseISO, format } from "date-fns";
import { graphErrorDetails } from "../../../../utils/helpers";
import { localizeWeightObservation } from "../../helpers";
import { checkCostCurrencyAndDisplayDollars } from "../../../../utils/streamline/checkCostCurrencyAndDisplayDollars";

//hooks
import useObservationLabelSessions from "../../hooks/useObservationLabelSessions";

//constants
import { RecognizableItemType } from "../../../../API";

const STRINGS = {
    LABEL_PENDING: "Label pending",
    CAPTURED: (date: string) =>
        `Captured on ${format(parseISO(date), "MM/dd/yy")} at ${format(
            parseISO(date),
            "h:mm aaaaa'm'"
        )} `,
    PAN_SIZE: "Pan size",
    GROSS_WEIGHT: "Gross weight",
    LABELED_AT: (date: string) =>
        date
            ? `Labeled on ${format(parseISO(date), "MM/dd/yy h:mm aaaaa'm'")}`
            : "",
    LABEL_LOGIC: (label: string) => (label ? `Label logic ${label}` : ""),
    ITEM_ID: (itemType: string) =>
        itemType == RecognizableItemType.MenuItem
            ? "Menu item ID"
            : itemType == RecognizableItemType.Ingredient
            ? "Ingredient item ID"
            : "Menu item/Ingredient ID",
    OID: "Observation ID",
    THING: "Unit name",
    SCANNING_LOCATION: "Scanning location",
    STATION: "Station selected on unit",
    NET_WEIGHT: ` net weight `,
    EMPTY_WEIGHT: "- lbs net weight",
    EMPTY_VALUE: " ($- value)",
    LOCATION: (station: string, parentLocation: string) => `${
        station ? station + " - " : ""
    } 
    ${parentLocation ? parentLocation : ""}`,
    MACHINE_LABEL_INCORRECT: (description: string) =>
        `Label Logic: This observation was automatically labeled as ${description}. The label has been changed to reflect the correct item. `,
};

const Container = styled.div`
    padding: 0px ${({ theme }) => theme.spacing.xmlarge}px
        ${({ theme }) => theme.spacing.xmlarge}px
        ${({ theme }) => theme.spacing.xmlarge}px;
    overflow: clip;
`;

const TagsWrapper = styled.div`
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.tiny}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.MARINE_LAYER};
`;

const IgnoreWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.medium}px;
`;

const MenuItemWrapper = styled.div`
    display: flex;
    padding: ${({ theme }) => theme.spacing.medium}px 0px;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
    flex-direction: column;
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.MARINE_LAYER};
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
    align-self: stretch;
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

type Props = {
    observationId: string;
    onClose: () => void;
    className?: string;
    refreshAfterToggleIgnore: () => void;
};

export default function ObservationOverlay({
    observationId,
    onClose,
    className,
    refreshAfterToggleIgnore,
}: Props) {
    const { colors } = useTheme();
    const [getObservation, data, isLoading, errors] = useQuery(observation);
    const observationTags = data?.tags;
    const [ignoreTagPresent, setIgnoreTagPresent] = useState(
        observationTags?.includes("IGNORE")
    );

    useEffect(() => {
        if (observationId) {
            setIgnoreTagPresent(observationTags?.includes("IGNORE"));
        }
    }, [observationId, observationTags]);

    useEffect(() => {
        if (observationId) {
            getObservation({ id: observationId });
        }
    }, [observationId]);

    const [observationDNE] = graphErrorDetails(
        errors?.errors,
        "object-does-not-exist"
    );

    const [_lastLabel, lastMachineLabel, lastLabledAt] =
        useObservationLabelSessions(data);

    const labelLogic =
        lastMachineLabel?.recognizableItemId == data?.bestLabel?.id
            ? STRINGS.LABEL_LOGIC(lastMachineLabel?.rationale)
            : STRINGS.MACHINE_LABEL_INCORRECT(lastMachineLabel?.description);

    const toggleIgnoreObservation = () => {
        setTimeout(() => {
            setIgnoreTagPresent(!ignoreTagPresent);
            refreshAfterToggleIgnore();
        }, 10000);
    };

    if (!observationId) {
        return <></>;
    }

    const headerDetails = {
        weight: data
            ? localizeWeightObservation(
                  data?.netWeight?.weight < 0
                      ? data.grossWeight
                      : data?.netWeight
              ) + STRINGS.NET_WEIGHT
            : STRINGS.EMPTY_WEIGHT,
        value: data?.value?.value
            ? ` (${checkCostCurrencyAndDisplayDollars(
                  data?.value?.value,
                  data?.value?.costCurrency
              )} value)`
            : STRINGS.EMPTY_VALUE,
        ignoreFlag: ignoreTagPresent ? "- Ignored" : "",
        label: data?.bestLabel?.name || STRINGS.LABEL_PENDING,
        location: STRINGS.LOCATION(
            data?.station?.name,
            data?.capturedBy?.location?.fullName.split(" - ")[0]
        ),
        capturedOn: data ? STRINGS.CAPTURED(data?.timestamp) : "-",
    };

    const tableDetails = [
        {
            detail: STRINGS.SCANNING_LOCATION,
            value: data?.capturedBy?.location?.name || "-",
        },
        { detail: STRINGS.THING, value: data?.capturedBy?.iotThingName || "-" },
        {
            detail: STRINGS.STATION,
            value: data?.station?.name || "-",
        },
        {
            detail: STRINGS.GROSS_WEIGHT,
            value: localizeWeightObservation(data?.grossWeight || ""),
        },
        { detail: STRINGS.PAN_SIZE, value: data?.container?.name || "Default" },
        { detail: STRINGS.OID, value: data?.id || "-" },
    ];

    return (
        <Overlay
            scroll={"scroll"}
            onClickCloseOverlay={onClose}
            overlayOpen={observationId}
            onClickedOutside={onClose}
            className={className}
        >
            {observationDNE ? (
                <PageNotFound />
            ) : (
                data && (
                    <Container>
                        <ObservationHeader headerDetails={headerDetails} />

                        <ObservationImages
                            images={data?.images || []}
                            observationId={data.id}
                        />
                        <LabelWrapper>
                            <Text
                                type="body"
                                size="medium"
                                color={colors.EXHAUST}
                            >
                                {STRINGS.LABELED_AT(lastLabledAt)}
                            </Text>
                            <Text
                                type="label"
                                size="medium"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {labelLogic}
                            </Text>
                        </LabelWrapper>
                        <TagsWrapper>
                            {data.tags.length > 0 ? (
                                data.tags.map((item, index) => {
                                    return (
                                        <ObservationTagPill
                                            ignoreLabel={false}
                                            label={item}
                                        />
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </TagsWrapper>
                        <MenuItemWrapper>
                            <Text
                                type="body"
                                size="medium"
                                color={colors.EXHAUST}
                            >
                                {STRINGS.ITEM_ID(
                                    data?.labelSessions[0]?.recognizableItemType
                                )}
                            </Text>
                            <Text
                                type="label"
                                size="medium"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {data?.bestLabel?.id || "-"}
                            </Text>
                        </MenuItemWrapper>
                        <ObservationDetailsTable tableDetails={tableDetails} />
                        <IgnoreWrapper>
                            <ObservationIgnoreToggle
                                observationId={observationId}
                                ignoreTagPresent={ignoreTagPresent}
                                toggleIgnoreObservation={
                                    toggleIgnoreObservation
                                }
                            />
                        </IgnoreWrapper>
                    </Container>
                )
            )}
        </Overlay>
    );
}
